import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import { DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT } from 'consts';
import { EQUALS } from "consts/chartConfig";
import {isArray, isUndefinedOrEmpty} from 'utils';

import DateFilter from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';
import FilterInput from 'components/SegmentFilterInput/input';
import FILTER_TYPES from 'components/SegmentFilterInput/types';
import { showError } from "components/FlashMessage/flashMessageSlice";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import MetaDataService from 'services/metadata';

import { whereTypes, whereOperators } from './types';

import './index.scss';

const whereTypeOptions = [
  {
    text: 'Count',
    value: whereTypes.COUNT,
  },
  {
    text: 'Average of property',
    value: whereTypes.AVERAGE_OF_PROPERTY,
  },
  {
    text: 'Total sum of property',
    value: whereTypes.TOTAL_SUM_OF_PROPERTY,
  },
];

const whereOperatorsOptions = [
  {
    text: '≥ (greater or equals)',
    value: whereOperators.GREATER_OR_EQUALS,
  },
  {
    text: '≏ (between)',
    value: whereOperators.BETWEEN,
  },
  {
    text: '≤ (less than or equals)',
    value: whereOperators.LESS_THAN_OR_EQUALS,
  },
  {
    text: '= (equals)',
    value: whereOperators.EQUALS,
  },
];

export default function Form({
  showWhereCondition = true,
  value = {
    paramEventName: '',
    paramDate: [
      moment().subtract(2, 'days').format(DATE_FORMAT),
      moment().add(2, 'days').format(DATE_FORMAT)
    ],
    paramFilter: [

    ],
    paramWhere: {
      type: '',
      name: '',
      operator: '',
      value: 1,
    },
  },
  onChange = () => { },
  onDelete = () => { },
  className,
}) {
  const { t } = useTranslation();
  const wrapper = useRef(null);
  const dateWrapper = useRef(null);

  const dispatch = useDispatch();
  const { appId } = useParams();

  const [paramEventNameOptions, setParamEventNameOptions] = useState([]);
  const [eventPropertyOptions, setEventPropertyOptions] = useState([]);

  const getParamEventNameOptions = async () => {
    try {
      const result = await MetaDataService.getEventNameList({ appId });

      setParamEventNameOptions(result.map((value) => ({ text: value, value })));
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const labelId = Math.floor(Math.random() * 100) + 1;

  const getEventPropertyOptions = async (fieldKey) => {
    try {
      if (!fieldKey) {
        return;
      }
      const result = await MetaDataService.getFieldValues({ appId, fieldKey });

      setEventPropertyOptions(result.map((value) => ({ text: value, value })));
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  const handleSelectEventNameChange = (fieldValue) => {
    onChange({
      ...value,
      paramEventName: fieldValue,
    });
  };

  const onDateChange = (newValue) => {
    onChange({
      ...value,
      paramDate: newValue,
    });
  };

  const handleSelectWhereTypeChange = (fieldValue) => {
    if (fieldValue === whereTypes.COUNT) {
      onChange({
        ...value,
        paramWhere: {
          ...value.paramWhere,
          type: fieldValue,
          name: '',
        }
      });
    } else {
      onChange({
        ...value,
        paramWhere: {
          ...value.paramWhere,
          name: eventPropertyOptions[0].value,
          type: fieldValue,
        }
      });
    }
  };

  const handleSelectWhereNameChange = (fieldValue) => {
    onChange({
      ...value,
      paramWhere: {
        ...value.paramWhere,
        name: fieldValue,
      }
    });
  };

  const handleSelectWhereOperatorChange = (fieldValue) => {
    const newValue = {
      ...value,
      paramWhere: {
        ...value.paramWhere,
        operator: fieldValue,
      }
    };

    if (fieldValue === whereOperators.BETWEEN) {
      newValue.paramWhere.value = [1, 1];
    } else {
      newValue.paramWhere.value = 1;

      if (fieldValue === whereOperators.LESS_THAN_OR_EQUALS) {
        newValue.paramWhere.includeDidNot = false;
      } else {
        delete newValue.paramWhere.includeDidNot;
      }
    }

    onChange(newValue);
  };

  const handleWhereValueChange = (event) => {

    onChange({
      ...value,
      paramWhere: {
        ...value.paramWhere,
        value: parseInt(event.target.value),
      }
    });
  };

  const handleWhereIncludeDidNotChange = (event) => {
    onChange({
      ...value,
      paramWhere: {
        ...value.paramWhere,
        includeDidNot: event.target.checked,
      }
    });
  };

  const handleWhereFirstValueChange = (event) => {

    onChange({
      ...value,
      paramWhere: {
        ...value.paramWhere,
        value: [parseInt(event.target.value), value.paramWhere.value[1]],
      }
    });
  };

  const handleWhereSecondValueChange = (event) => {
    onChange({
      ...value,
      paramWhere: {
        ...value.paramWhere,
        value: [value.paramWhere.value[0], parseInt(event.target.value)],
      }
    });
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const addFilter = () => {
    const newValue = { ...value };

    newValue.paramFilter.push({
      type: FILTER_TYPES.EVENT_PROPERTY,
      value: {
        property: eventPropertyOptions[0] ? eventPropertyOptions[0].value : '',
        operator: EQUALS,
        value: "",
      },
    });

    onChange(newValue);
  };

  const deleteFilter = (index) => {
    const newValue = { ...value };

    newValue.paramFilter.splice(index, 1);

    onChange(newValue);
  };

  const handleFilterChange = (data, index) => {
    const newValue = { ...value };

    newValue.paramFilter[index] = data;

    onChange(newValue);
  };

  useEffect(() => {
    getParamEventNameOptions();
  }, [appId]);

  useEffect(() => {
    getEventPropertyOptions(value.paramEventName);
  }, [value.paramEventName]);

  useEffect(() => {
    if (isArray(paramEventNameOptions) && paramEventNameOptions.length > 0 && isUndefinedOrEmpty(value.paramEventName)) {
      onChange({
        ...value,
        paramEventName: paramEventNameOptions[0].value,
      });
    }
  }, [paramEventNameOptions]);

  return (
    <div className={`relative grid grid-cols-1 gap-2 ${className}`}>
      <div className='flex items-center justify-start gap-4'>
        <FontAwesomeIcon onClick={onDelete} className='absolute text-red-500 right-2 top-6 cursor-pointer' icon={faTrashCan} />
        <div>
          <TailwindComboboxWithoutForm items={paramEventNameOptions} onChange={handleSelectEventNameChange} value={value.paramEventName} />
        </div>
        <div>
          <ToggleBox ref={wrapper} onClose={onClose}>
            <ToggleBox.Button>
              <div className="ds-input">
                <span>{moment(value.paramDate[0]).format(DISPLAY_DATE_CALENDAR_FORMAT)} - {moment(value.paramDate[1]).format(DISPLAY_DATE_CALENDAR_FORMAT)}</span>
              </div>
            </ToggleBox.Button>

            <ToggleBox.Content>
              <DateFilter ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter}
                value={value.paramDate} />
            </ToggleBox.Content>
          </ToggleBox>
        </div>
      </div>

      <div className='flex items-center justify-start gap-4'>
        <div onClick={addFilter} className='text-blue-700 hover:text-blue-500 cursor-pointer select-none ds-text-color'>
          + {t("FILTER_BY")}
        </div>
      </div>

      {/*Event property*/}
      {value.paramFilter.map((item, index) => (
        <div className='flex items-center justify-start gap-4'>
          <FilterInput
            key={index}
            value={item}
            onChange={(value) => handleFilterChange(value, index)}
            eventPropertyOptions={eventPropertyOptions}
            onDelete={() => deleteFilter(index)}
          />
        </div>
      ))}

      {
        showWhereCondition &&
        <>
          <div className='flex items-center justify-start gap-4'>
            {t('WHERE')}
          </div>
          <div className='flex items-center justify-start gap-4'>
            <div>
              <TailwindComboboxWithoutForm items={whereTypeOptions} onChange={handleSelectWhereTypeChange} />
            </div>

            {/*count input*/}
            {
              value.paramWhere.type !== whereTypes.COUNT &&
              <div>
                <TailwindComboboxWithoutForm items={eventPropertyOptions} onChange={handleSelectWhereNameChange} />
              </div>
            }

            {/*greater operator*/}
            <div>
              <TailwindComboboxWithoutForm items={whereOperatorsOptions} onChange={handleSelectWhereOperatorChange} />
            </div>
            <div>
              {
                value.paramWhere.operator === whereOperators.BETWEEN
                  ?
                  <>
                    <input
                      value={value.paramWhere.value[0]}
                      onChange={handleWhereFirstValueChange}
                      type='number'
                      className="ds-input"
                    />

                    <input
                      value={value.paramWhere.value[1]}
                      onChange={handleWhereSecondValueChange}
                      type='number'
                      className="ds-input"
                    />
                  </>
                  :
                  <input
                    value={value.paramWhere.value}
                    onChange={handleWhereValueChange}
                    type='number'
                    className="ds-input"
                  />
              }
            </div>
            <div>
              {
                value.paramWhere.operator === whereOperators.LESS_THAN_OR_EQUALS &&
                <div className='flex justify-start items-center'>
                  <input
                    value={value.paramWhere.includeDidNot}
                    onChange={handleWhereIncludeDidNotChange}
                    type='checkbox'
                    id={labelId}
                  />
                  <label className='ms-2'
                    for={labelId}>{t('INCLUDE_PEOPLE_WHO_DID_NOT_DO_THIS_EVENT_IN_THE_SELECTED_TIME_FRAME')}</label>
                </div>
              }
            </div>
          </div>
        </>
      }
    </div>
  );
}
