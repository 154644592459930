import { useTranslation } from 'react-i18next';

import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import types from './types';

const operatorOptions = [
  {
    text: '∋ (contains)',
    value: types.CONTAINS,
  },
  {
    text: '∌ (does not contain)',
    value: types.NOT_CONTAINS,
  },
];

export default function GeographyInput({
  value = {
    operator: types.CONTAINS,
    value: {
      country: '',
      state: '',
      city: '',
    },
  },
  onChange = () => { },
}) {
  const { t } = useTranslation();

  const handleSelectOperatorChange = (fieldValue) => {
    const newValue = { ...value };

    newValue.name = fieldValue;

    onChange(newValue);
  };

  const handleCountryChange = (event) => {
    const newValue = { ...value };

    newValue.value.country = event.target.value;

    onChange(newValue);
  };

  const handleStateChange = (event) => {
    const newValue = { ...value };

    newValue.value.state = event.target.value;

    onChange(newValue);
  };

  const handleCityChange = (event) => {
    const newValue = { ...value };

    newValue.value.city = event.target.value;

    onChange(newValue);
  };

  return (
    <div className='flex justify-start items-center w-full'>

      <TailwindComboboxWithoutForm items={operatorOptions} onChange={handleSelectOperatorChange} />

      <input
        value={value.value.country}
        onChange={handleCountryChange}
        placeholder={t('COUNTRY')}
        className="ds-input"
      />

      <input
        value={value.value.state}
        onChange={handleStateChange}
        placeholder={t('STATE')}
        className="ds-input"
      />

      <input
        value={value.value.city}
        onChange={handleCityChange}
        placeholder={t('CITY')}
        className="ds-input"
      />
    </div>
  );
}
