import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { PATHS, EMAIL_REGEX } from "consts";

import TextInput from 'components/TextInput';
import { showError, showInfo } from 'components/FlashMessage/flashMessageSlice';

import UserService from 'services/user';
import EmailService from 'services/email';

import { Header } from "../landing_page/home/Header";
import { Footer } from "../landing_page/home/Footer";
import { SubLayout } from "../landing_page/home/SubLayout";
import { Faqs } from "../landing_page/home/Faqs";
import LPTextBlock from "views/landing_page/LPTextBlock";
import {DsIconSlogan} from "../../components/DsIcon";
import LoadingSpinner from "../../components/LoadingSpinner";

export default function CreateAccount() {
  const { t } = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get('token');

  const isLogin = useSelector((state) => !!state.user.refreshToken);
  const [verifyingUser, setVerifyingUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const watchPassword = methods.watch('password');

  const validateConfirmPassword = (value) => {
    if (value !== watchPassword) {
      return t('PASSWORDS_DO_NOT_MATCH');
    }

    return true;
  };

  useEffect(() => {
    if (isLogin) {
      navigate(PATHS.APP);
    }
  }, [isLogin]);

  const getTokenInfo = async () => {
    try {
      const result = await UserService.getTokenInfo({ token })
      setVerifyingUser(result);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  useEffect(() => {
    if (token) {
      getTokenInfo();
    }
  }, []);

  const onSubmit = async (data) => {
    try {
      if (token) {
        data.token = token;
      }
      setIsLoading(true);

      const { message } = await EmailService.verify(data);

      dispatch(showInfo({ message: "Create account successfully." }));
      navigate(PATHS.HOMEPAGE);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }

    setIsLoading(false);
  };

  return (
    <>
      <Header />
      <main className={"pb-16 pt-56 lg:pt-56"}>
        <div className={"grid grid-rows-1 gap-y-36"}>
          <div className={"lp-background-2 py-10"}>
            <div className={"flex grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl px-6 lg:px-8 gap-8"}>
              <div>
                <h1 className="mx-auto max-w-4xl font-display text-5xl font-medium tracking-tight">
                  Harnessing Data & AI{' '}
                  <span className="relative whitespace-nowrap text-blue-600">
                    <DsIconSlogan />
                    <span className="relative">for Growth</span>
                  </span>
                </h1>
                <p className="mx-auto mt-6 max-w-2xl lp-text-desc-bg-light">
                  Transform insights into sustainable growth. Improve ROI, LTV and increase app installs by understanding
                  deeply your customers & your campaigns.
                </p>
              </div>
              <div className={"w-full bg-white rounded-lg shadow justify-center"}>
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                    {t('CREATE_ACCOUNT')}
                  </h1>
                  <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-4 md:space-y-6">
                      <div>
                        <TextInput
                          label={t('YOUR_EMAIL')}
                          name="email"
                          className="w-full"
                          value={verifyingUser && verifyingUser.email ? verifyingUser.email : undefined}
                        />
                      </div>
                      <div>
                        <TextInput
                          label={t('PASSWORD')}
                          name="password"
                          type="password"
                          className="w-full"
                          rules={
                            {required: t('VALUE_IS_REQUIRED', {Value: t('PASSWORD')})}
                          }
                        />
                      </div>
                      <div>
                        <TextInput
                          label={t('CONFIRM_PASSWORD')}
                          name="confirmPassword"
                          type="password"
                          className="w-full mb-1.5"
                          rules={
                            {
                              required: t('VALUE_IS_REQUIRED', {Value: t('CONFIRM_PASSWORD')}),
                              validate: validateConfirmPassword
                            }
                          }
                        />
                      </div>
                      <button
                        className="ds-button-3"
                      >
                        {isLoading ?
                          <>
                            <LoadingSpinner className="me-3" textColor="text-blue-500" />
                            {t('LOADING')}
                          </>
                          :
                          t('CREATE_ACCOUNT')
                        }
                      </button>
                    </form>
                  </FormProvider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Faqs/>
      <Footer/>
    </>
  )
}
