
import { useEffect, useState } from 'react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { Combobox } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function TailwindComboboxWithoutForm({
  items,
  value,
  onChange = () => { },
  className = '',
  disabled = false
}) {
  const [query, setQuery] = useState('')
  const [selectedItem, setSelectedItems] = useState(items.length > 0 ? items[0] : {})

  const filteredItems =
    query === ''
      ? items
      : items.filter((item) => {
        return item.text.toLowerCase().includes(query.toLowerCase())
      })

  const onValueChange = (item) => {
    setSelectedItems(item);
    onChange(item.value);
  }

  useEffect(() => {
    const selected = items.find((item) => item.value === value);

    if (selected) {
      setSelectedItems(selected);
    } else {
      setSelectedItems(items.length > 0 ? items[0] : {})
    }
  }, [value, items]);

  // useEffect(() => {
  //   setSelectedItems(items.length > 0 ? items[0] : {})
  // }, [items]);

  return (
    <Combobox as="div" value={selectedItem} onChange={onValueChange}>
      <div className="relative ">
        <Combobox.Input
          className={`w-full ds-input-text ds-input ${className} ds-violet-border`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => item?.text} readOnly
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="ds-option shadow-lg ring-1 ring-black max-h-72">
            {filteredItems.map((item, index) => (
              <Combobox.Option
                key={index}
                value={item}
                className={({ active }) =>
                  classNames(
                    'ds-input-text relative cursor-pointer select-none py-2 pl-3 pr-9 max-h-30',
                    active ? 'ds-hover-color' : ''
                  )
                }
                disabled={disabled}
              >
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected)}>{item.text}</span>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active
                        )}
                      >
                        <FontAwesomeIcon className="h-4 w-4" aria-hidden="true" icon={faCheck} />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
