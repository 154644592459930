import { CONTAINS, EQUALS } from "consts/chartConfig";


export const typeValue =  {
  EVENT_PROPERTY: 'event_properties',
  TIME_OF_THE_DAY: 'Time of the day',
  DAY_OF_THE_WEEK: 'Day of the week',
};

export const operatorValue = {
  EQUALS: EQUALS,
  CONTAINS: CONTAINS,
};
