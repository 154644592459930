import { useMemo } from 'react';

import MultiSelectOption from 'components/MultiSelectOption/WithoutForm';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";


export default function MultiSelectInput({
  value = {
    name: 'Email',
    value: [],
  },
  nameOptions = [],
  valueOptions = [],
  onChange = () => { },
}) {
  const handleSelectNameChange = (fieldValue) => {
    const newValue = { ...value };

    newValue.name = fieldValue;

    onChange(newValue);
  };

  const handleSelectValueChange = (newValue) => {
    onChange({
      ...value,
      value: newValue,
    });
  };

  const options = useMemo(() => {
    return valueOptions.map(item => ({text: item, value: item}))
  }, [valueOptions]);

  return (
    <div className='flex justify-start items-center w-full'>

      <TailwindComboboxWithoutForm items={nameOptions} onChange={handleSelectNameChange} />

      <MultiSelectOption value={value.value} options={options} onChange={handleSelectValueChange} />
    </div>
  );
}
