import {isArray, isObject, isUndefinedOrEmpty} from "utils";
import {getDateKeyNow} from "utils/util";
import {EXISTS, NOT_EXISTS, propertyInputType, whereValue} from "consts/chartConfig";
import SegmentFilterType from "components/SegmentFilterInput/types";

import {operators} from "./UsersWhoDid/types";
import {whereTypes} from "./UsersWhoDid/Form/types";
import UserPropertyInputOperatorTypes from './DisplayCommonProperties/PropertyInput/UserPropertyInput/types';

const FILTER_TYPE = {
  WHERE: "WHERE",
  HAVING: "HAVING",
  GROUP_BY: "GROUPBY",
}

const AGGREGATE_TYPE = {
  COUNT: "COUNT",
  AVG: "AVG",
  SUM: "SUM",
};

const OPERATOR_TYPE = {
  GREATER_OR_EQUALS: ">=",
  LESS_THAN_OR_EQUALS: "<=",
  EQUALS: "=",
  LESS_THAN: "<",
  IN: "IN",
};

const FIELD_NAME = {
  DATE_KEY: "date_key",
  EVENT_NAME: "event_name",
  HOUR: "hour",
  DAY_OF_WEEK: "day_of_week",
  CUSTOMER_ID: "customer_id",
  CUSTOMER_PHONE: "phone",
  CUSTOMER_EMAIL: "email",
  FREQUENCY: "frequency",
  EVENT_PROPERTIES: 'event_properties',
}

const BLOCK_TYPE = {
  INTERSECT_DISTINCT: "INTERSECT DISTINCT",
  UNION_DISTINCT: "UNION DISTINCT",
}

const SET_OPERATIONS = [
  "INTERSECT DISTINCT",
  "EXCEPT DISTINCT",
  "INTERSECT DISTINCT"
];

/**
 * Represents a filter object.
 * @typedef {Object} Filter
 * @property {string} filter_type - The type of the filter.
 * @property {string} aggregate - The aggregate function for the filter.
 * @property {string} field - The field name for the filter.
 * @property {string} operator - The operator for the filter.
 * @property {any} value - The value associated with the filter.
 */

/**
 * Checks if the provided data indicates that the user's like is verified.
 * @param {Object} data - The data containing user like information.
 * @returns {boolean} Returns true if the user's like is verified, otherwise false.
 */
const isUserLikeVerified = (data) => {
  const conditions = [
    !isObject(data.userLike),
    isUndefinedOrEmpty(data.userLike.operator),
    !isArray(data.userLike.value),
    data.userLike.value.length === 0,
  ];

  if (conditions.some(element => element === true)) {
    return false;
  }

  for (const value of data.userLike.value) {
    const conditions = [
      isUndefinedOrEmpty(value.paramDate),
      !isArray(value.paramDate),
      value.paramDate.length !== 2,
      isUndefinedOrEmpty(value.paramEventName),
      isUndefinedOrEmpty(value.paramFrequency),
      value.paramFrequency === whereValue.AT_LEAST && isUndefinedOrEmpty(value.paramFrequencyAtLeastValue)
    ];

    if (conditions.some(element => element === true)) {
      return false;
    }

    switch (value.type) {
      case SegmentFilterType.EVENT_PROPERTY:
        if (!isObject(value.paramEventProperty)) {
          return false;
        }
        break;
      case SegmentFilterType.TIME_OF_THE_DAY:
        if (isUndefinedOrEmpty(value.paramHour) || !isArray(value.paramHour) || value.paramHour.length !== 2) {
          return false;
        }
        break;
      case SegmentFilterType.DAY_OF_THE_WEEK:
        if (isUndefinedOrEmpty(value.dayOfWeek) || !isArray(value.dayOfWeek) || value.dayOfWeek.length === 0) {
          return false;
        }
        break;
      default:
        return false;
    }
  }

  return true;
}

/**
 * Represents a filter object.
 * @typedef {Object} UserLikeResult
 * @property {number} _id - The id for result filter.
 * @property {Array<Filter>} filters - The filter array.
 */

/**
 * Proceeds the user like data to generate filters based on user preferences.
 * @param {Object} data - The object containing user like data.
 * @param {Object[]} data.value - The array of user like values.
 * @param {string[]} data.value[].paramDate - The date parameters for each user like value.
 * @param {string} data.value[].paramDate[0] - The start date for each user like value.
 * @param {string} data.value[].paramDate[1] - The end date for each user like value.
 * @param {string} data.value[].paramEventName - The event name for each user like value.
 * @param {string} data.value[].paramFrequency - The frequency of the user like event.
 * @param {string} data.value[].paramFrequencyAtLeastValue - The minimum frequency value for "at least" frequency type.
 * @param {string} data.value[].type - The type of user like data.
 * @param {Object} data.value[].paramEventProperty - The event property parameters for each user like value.
 * @param {string} data.value[].paramEventProperty.name - The name of the event property.
 * @param {string} data.value[].paramEventProperty.operator - The operator for the event property.
 * @param {string} data.value[].paramEventProperty.value - The value of the event property.
 * @param {string[]} data.value[].paramHour - The hour range for "Time of the day" type.
 * @param {string[]} data.value[].dayOfWeek - The selected days of the week for "Day of the week" type.
 * @returns {Array<UserLikeResult>} Returns an array of objects containing filters based on user like preferences.
 */
const proceedUserLike = (data) => {
  /** @type {Array<UserLikeResult>} */
  const result = [];

  data.value.forEach((value, index) => {
    /** @type {Array<Filter>} */
    const filters = [];

    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.GREATER_OR_EQUALS,
      value: value.paramDate[0]
    })
    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
      value: value.paramDate[1]
    })
    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.EVENT_NAME,
      operator: OPERATOR_TYPE.EQUALS,
      value: value.paramEventName
    })

    // if (p.paramFrequency === "at least") {
    //   filters.push({ field: FIELD_NAME.FREQUENCY, operator: OPERATOR_TYPE.EQUALS, value: value.paramEventProperty + "||" + value.paramFrequencyAtLeastValue })
    // } else {
    //   filters.push({ field: FIELD_NAME.FREQUENCY, operator: OPERATOR_TYPE.EQUALS, value: value.paramFrequency })
    // }

    switch (value.type) {
      case SegmentFilterType.EVENT_PROPERTY:
        if (!isUndefinedOrEmpty(value.paramEventProperty.name) && !isUndefinedOrEmpty(value.paramEventProperty.operator) && !isUndefinedOrEmpty(value.paramEventProperty.value)) {
          filters.push({
            filter_type: FILTER_TYPE.WHERE,
            field: FIELD_NAME.EVENT_PROPERTIES,
            filters: [{
              field: value.paramEventProperty.name,
              operator: value.paramEventProperty.operator,
              value: value.paramEventProperty.value
            }],
          })
        }
        break;
      case SegmentFilterType.TIME_OF_THE_DAY:
        filters.push({
          filter_type: FILTER_TYPE.WHERE,
          field: FIELD_NAME.HOUR,
          operator: OPERATOR_TYPE.GREATER_OR_EQUALS,
          value: value.paramHour[0]
        })
        filters.push({
          filter_type: FILTER_TYPE.WHERE,
          field: FIELD_NAME.HOUR,
          operator: OPERATOR_TYPE.LESS_THAN,
          value: value.paramHour[1]
        })

        break;
      case SegmentFilterType.DAY_OF_THE_WEEK:
        filters.push({
          filter_type: FILTER_TYPE.WHERE,
          field: FIELD_NAME.DAY_OF_WEEK,
          operator: OPERATOR_TYPE.IN,
          value: value.dayOfWeek
        })
        break;
    }

    result.push({
      _id: index,
      query_params: [FIELD_NAME.CUSTOMER_ID, FIELD_NAME.CUSTOMER_PHONE, FIELD_NAME.CUSTOMER_EMAIL],
      filters: filters
    });
  });

  return result;
}

/**
 * Verifies if a filter object is correctly structured.
 * @param {Object} data - The filter object to be verified.
 * @param {string} data.type - The type of the filter.
 * @param {(Object|Array)} data.value - The value of the filter, which can be an object or an array.
 * @returns {boolean} Returns true if the filter object is correctly structured, otherwise returns false.
 */
const isFilterVerified = (data) => {
  if (isUndefinedOrEmpty(data.type)) {
    return false;
  }

  if (!isObject(data.value) && !isArray(data.value)) {
    return false;
  }

  return true;
}

/**
 * Verifies if the user DID "where" clause object is correctly structured.
 * @param {Object} data - The user DID "where" clause object to be verified.
 * @param {string} data.type - The type of the "where" clause.
 * @param {*} data.value - The value of the "where" clause.
 * @param {string} data.operator - The operator used in the "where" clause.
 * @returns {boolean} Returns true if the user DID "where" clause object is correctly structured, otherwise returns false.
 */
const isUserDidWhereVerified = (data) => {
  return !(isUndefinedOrEmpty(data.type) || isUndefinedOrEmpty(data.value) || isUndefinedOrEmpty(data.operator));
}

/**
 * Verifies if the user DID object is correctly structured.
 * @param {Object} data - The user DID object to be verified.
 * @param {Object} data.userDid - The user DID information.
 * @param {string} data.userDid.operator - The operator used in the user DID object.
 * @param {Array<Object>} data.userDid.value - An array of user DID values.
 * @param {Array} data.userDid.value.paramDate - The parameter date array in user DID values.
 * @param {string} data.userDid.value.paramEventName - The parameter event name in user DID values.
 * @param {Array<Object>} data.userDid.value.paramFilter - An array of parameter filter objects in user DID values.
 * @param {Object} data.userDid.value.paramWhere - The "where" clause object in user DID values.
 * @returns {boolean} Returns true if the user DID object is correctly structured, otherwise returns false.
 */
const isUserDidVerified = (data) => {
  const conditions = [
    !isObject(data.userDid),
    isUndefinedOrEmpty(data.userDid.operator),
    !isArray(data.userDid.value),
    data.userDid.value.length === 0,
  ];

  if (conditions.some(element => element === true)) {
    return false;
  }

  for (const value of data.userDid.value) {
    const conditions = [
      isUndefinedOrEmpty(value.paramDate),
      !isArray(value.paramDate),
      value.paramDate.length !== 2,
      isUndefinedOrEmpty(value.paramEventName),
      !isArray(value.paramFilter),
      !isObject(value.paramWhere),
    ];

    if (conditions.some(element => element === true)) {
      return false;
    }

    for (const filter of value.paramFilter) {
      if (!isFilterVerified(filter)) {
        return false;
      }
    }

    if (!isUserDidWhereVerified(value.paramWhere)) {
      return false;
    }
  }

  return true;
}

/**
 * Represents a having object.
 * @typedef {Object} Having
 * @property {string} field - The field name for the filter.
 * @property {string} math - The type of having operator.
 * @property {string} operator - The operator for the filter.
 * @property {any} value - The value associated with the filter.
 */


/**
 * Represents a filter object.
 * @typedef {Object} UserDidFilterResult
 * @property {number} _id - The id for result filter.
 * @property {Array<Filter>} filters - The filter array.
 * @property {Array<Having>} having - The having array.
 */


/**
 * Proceeds the user did data to generate filters and having conditions.
 * @param {Object} data - The object containing user did data.
 * @param {Object[]} data.value - The array of user did values.
 * @param {string[]} data.value[].paramDate - The date parameters for each user did value.
 * @param {string} data.value[].paramDate[0] - The start date for each user did value.
 * @param {string} data.value[].paramDate[1] - The end date for each user did value.
 * @param {string} data.value[].paramEventName - The event name for each user did value.
 * @param {Object[]} data.value[].paramFilter - The array of filters for each user did value.
 * @param {string} data.value[].paramFilter[].type - The type of filter.
 * @param {any} data.value[].paramFilter[].value - The value of the filter.
 * @param {string} data.value[].paramFilter[].operator - The operator of the filter.
 * @param {Object} data.value[].paramFilter[].value - The value object of the filter.
 * @param {string} data.value[].paramFilter[].value.property - The property of the filter value.
 * @param {string} data.value[].paramFilter[].value.operator - The operator of the filter value.
 * @param {string} data.value[].paramFilter[].value.value - The value of the filter value.
 * @param {Object} data.value[].paramWhere - The where condition for each user did value.
 * @param {string} data.value[].paramWhere.type - The type of the where condition.
 * @param {string} data.value[].paramWhere.operator - The operator of the where condition.
 * @param {number} data.value[].paramWhere.value - The value of the where condition.
 * @param {string} data.value[].paramWhere.name - The name of the where condition.
 * @returns {Array<UserDidFilterResult>} Returns an array of objects containing filters and having conditions.
 */
const proceedUserDid = (data) => {
  /** @type {Array<UserDidFilterResult>} */
  const result = [];

  data.value.forEach((value, index) => {
    /** @type {Array<Filter>} */
    const filters = [];

    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.GREATER_OR_EQUALS,
      value: value.paramDate[0]
    });
    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
      value: value.paramDate[1]
    });
    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.EVENT_NAME,
      operator: OPERATOR_TYPE.EQUALS,
      value: value.paramEventName
    });

    const sub_filters = [];
    value.paramFilter.forEach(filter => {
      if (filter.type === SegmentFilterType.EVENT_PROPERTY && filter.value && (!isUndefinedOrEmpty(filter.value.value) || filter.value.operator === EXISTS || filter.value.operator === NOT_EXISTS)) {
        sub_filters.push({
          field: filter.value.property,
          operator: filter.value.operator,
          value: filter.value.value
        });
      }
    });

    if (sub_filters.length > 0) {
      filters.push({
        filter_type: FILTER_TYPE.WHERE,
        field: FIELD_NAME.EVENT_PROPERTIES,
        filters: sub_filters
      });
    }

    if (value.paramWhere && !isUndefinedOrEmpty(value.paramWhere.type)) {
      switch (value.paramWhere.type) {
        case whereTypes.COUNT:
          filters.push({
            filter_type: FILTER_TYPE.HAVING,
            aggregate: AGGREGATE_TYPE.COUNT,
            operator: value.paramWhere.operator,
            value: value.paramWhere.value,
            field: FIELD_NAME.CUSTOMER_ID
          })
          filters.push({
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_ID
            },
            {
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_PHONE
            },
            {
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_EMAIL
            })
          break;
        case whereTypes.AVERAGE_OF_PROPERTY:
          filters.push({
            filter_type: FILTER_TYPE.HAVING,
            aggregate: AGGREGATE_TYPE.AVG,
            field: FIELD_NAME.EVENT_PROPERTIES,
            filters:
              [{
                operator: value.paramWhere.operator,
                value: value.paramWhere.value,
                field: value.paramWhere.name
              }]
          })
          filters.push({
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_ID
            },
            {
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_PHONE
            },
            {
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_EMAIL
            })
          break;
        case whereTypes.TOTAL_SUM_OF_PROPERTY:
          filters.push({
            filter_type: FILTER_TYPE.HAVING,
            aggregate: AGGREGATE_TYPE.SUM,
            field: FIELD_NAME.EVENT_PROPERTIES,
            filters:
              [{
                operator: value.paramWhere.operator,
                value: value.paramWhere.value,
                field: value.paramWhere.name
              }]
          })
          filters.push({
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_ID
            },
            {
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_PHONE
            },
            {
              filter_type: FILTER_TYPE.GROUP_BY,
              field: FIELD_NAME.CUSTOMER_EMAIL
            })
          break;
      }
    }

    result.push({
      _id: index,
      query_params: [FIELD_NAME.CUSTOMER_ID, FIELD_NAME.CUSTOMER_PHONE, FIELD_NAME.CUSTOMER_EMAIL],
      filters: filters,
    })
  });

  return result;
}

/**
 * Checks if the user did not data is verified.
 * @param {Object} data - The object containing user did not data.
 * @param {Object} data.didNotDo - The user did not data object.
 * @param {Object[]} data.didNotDo.value - The array of user did not values.
 * @param {string[]} data.didNotDo.value[].paramDate - The date parameters for each user did not value.
 * @param {string} data.didNotDo.value[].paramDate[0] - The start date for each user did not value.
 * @param {string} data.didNotDo.value[].paramDate[1] - The end date for each user did not value.
 * @param {string} data.didNotDo.value[].paramEventName - The event name for each user did not value.
 * @param {Object[]} data.didNotDo.value[].paramFilter - The array of filters for each user did not value.
 * @returns {boolean} Returns true if the user did not data is verified, otherwise false.
 */
const isUserDidNotVerified = (data) => {
  const conditions = [
    !isObject(data.didNotDo),
    !isArray(data.didNotDo.value),
    data.didNotDo.value.length === 0,
  ];

  if (conditions.some(element => element === true)) {
    return false;
  }

  for (const value of data.didNotDo.value) {
    const conditions = [
      isUndefinedOrEmpty(value.paramDate),
      !isArray(value.paramDate),
      value.paramDate.length !== 2,
      isUndefinedOrEmpty(value.paramEventName),
      !isArray(value.paramFilter),
    ];

    if (conditions.some(element => element === true)) {
      return false;
    }

    for (const filter of value.paramFilter) {
      if (!isFilterVerified(filter)) {
        return false;
      }
    }
  }

  return true;
}

/**
 * Represents a filter object.
 * @typedef {Object} UserDidNotFilterResult
 * @property {number} _id - The id for result filter.
 * @property {Array<Filter>} filters - The filter array.
 */

/**
 * Processes user did not data to generate filters.
 * @param {Object} data - The object containing user did not data.
 * @param {Object[]} data.value - The array of user did not values.
 * @param {Object[]} data.value[].paramDate - The date parameters for each user did not value.
 * @param {string} data.value[].paramDate[0] - The start date for each user did not value.
 * @param {string} data.value[].paramDate[1] - The end date for each user did not value.
 * @param {string} data.value[].paramEventName - The event name for each user did not value.
 * @param {Object[]} data.value[].paramFilter - The array of filters for each user did not value.
 * @param {string} data.value[].paramFilter[].type - The type of filter for each user did not value.
 * @param {Object} data.value[].paramFilter[].value - The value of the filter for each user did not value.
 * @param {string} data.value[].paramFilter[].value.property - The property of the filter for each user did not value.
 * @param {string} data.value[].paramFilter[].value.operator - The operator of the filter for each user did not value.
 * @param {any} data.value[].paramFilter[].value.value - The value of the filter for each user did not value.
 * @returns {Array<UserDidNotFilterResult>} Returns an array of objects containing filters generated from the user did not data.
 */
const proceedUserDidNot = (data) => {
  /** @type {Array<UserDidNotFilterResult>} */
  const result = [];

  data.value.forEach((value, index) => {
    /** @type {Array<Filter>} */
    const filters = [];

    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.GREATER_OR_EQUALS,
      value: value.paramDate[0]
    })
    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
      value: value.paramDate[1]
    })
    filters.push({
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.EVENT_NAME,
      operator: OPERATOR_TYPE.EQUALS,
      value: value.paramEventName
    })

    value.paramFilter.forEach(filter => {
      if (filter.type === SegmentFilterType.EVENT_PROPERTY && filter.value && !isUndefinedOrEmpty(filter.value.value)) {
        filters.push({field: filter.value.property, operator: filter.value.operator, value: filter.value.value});
      } else if (!isUndefinedOrEmpty(filter.value)) {
        filters.push({field: filter.type, operator: filter.operator, value: filter.value});
      }
    });

    result.push({
      _id: index,
      query_params: [FIELD_NAME.CUSTOMER_ID, FIELD_NAME.CUSTOMER_PHONE, FIELD_NAME.CUSTOMER_EMAIL],
      filters: filters,
    });
  });

  return result;
}

/**
 * Checks if the display common properties are verified.
 * @param {Object} data - The data object containing display common properties.
 * @returns {boolean} Returns true if the display common properties are verified, otherwise false.
 */
const isDisplayCommonVerified = (data) => {
  if (!isArray(data.displayCommonProperties) || data.displayCommonProperties.length === 0) {
    return false;
  }

  for (const property of data.displayCommonProperties) {
    if (isUndefinedOrEmpty(property.type)) {
      return false;
    }

    if (
      isUndefinedOrEmpty(property.value) &&
      ![UserPropertyInputOperatorTypes.EXISTS, UserPropertyInputOperatorTypes.NOT_EXISTS].includes(property.operator)
    ) {
      return false;
    }
  }

  return true;
};

/**
 * @typedef {Object} DisplayCommonProperty
 * @property {string} name - The name of the property.
 * @property {string} type - The type of property.
 * @property {string} operator - The operator to be used for comparison.
 * @property {string} value - The value to compare against.
 */

/**
 * Processes display common properties data to generate filters.
 * @param {Array<DisplayCommonProperty>} data - The array containing display common properties data.
 * @returns {Array<Filter>} Returns an array of filters generated from the provided data.
 */
const proceedDisplayCommonProperties = (data) => {
  const filters = [];
  const result = [];
  const dateKey = getDateKeyNow();

  filters.push(
    {
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.GREATER_OR_EQUALS,
      value: "2024-01-01"
    },
    {
      filter_type: FILTER_TYPE.WHERE,
      field: FIELD_NAME.DATE_KEY,
      operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
      value: dateKey
    }
  );

  const operatorsNoNeedValue = [
    UserPropertyInputOperatorTypes.EXISTS,
    UserPropertyInputOperatorTypes.NOT_EXISTS,
  ];

  // Process user properties and other properties
  data.forEach((property) => {
    if (property.type === propertyInputType.USER_PROPERTIES) {
      // Handle user properties
      if (!isUndefinedOrEmpty(property.value)) {
        if (property.operator === UserPropertyInputOperatorTypes.BETWEEN) {

          filters.push({
            filter_type: FILTER_TYPE.WHERE,
            field: property.name,
            operator: OPERATOR_TYPE.GREATER_OR_EQUALS,
            value: property.value[0]
          });

          filters.push({
            filter_type: FILTER_TYPE.WHERE,
            field: property.name,
            operator: OPERATOR_TYPE.LESS_THAN_OR_EQUALS,
            value: property.value[1]
          });

        } else {

          filters.push({
            filter_type: FILTER_TYPE.WHERE,
            field: property.name,
            operator: property.operator,
            value: property.value
          });

        }
      } else if (operatorsNoNeedValue.includes(property.operator)) {

        filters.push({
          filter_type: FILTER_TYPE.WHERE,
          field: property.name,
          operator: property.operator
        });

      }
    } else {
      // Handle other properties
      const operator = isUndefinedOrEmpty(property.operator) ?
        (isArray(property.value) ? OPERATOR_TYPE.IN : OPERATOR_TYPE.EQUALS) :
        property.operator;

      filters.push({
        filter_type: FILTER_TYPE.WHERE,
        field: property.type,
        operator: operator,
        value: property.value
      });
    }
  });

  result.push({
    _id: 0,
    query_params: [FIELD_NAME.CUSTOMER_ID, FIELD_NAME.CUSTOMER_PHONE, FIELD_NAME.CUSTOMER_EMAIL],
    filters: filters,
  })

  return result;
}

/**
 * Generates a request payload based on the provided data.
 * @param {Object} data - The data containing various parameters.
 * @returns {Array} Returns an array representing the request payload.
 */
export const proceedParam = (data) => {
  const requestPayload = [];

  if (isUserLikeVerified(data)) {
    requestPayload.push({
      block_id: 1,
      block_type: data.userLike.operator === operators.AND ? BLOCK_TYPE.INTERSECT_DISTINCT : BLOCK_TYPE.UNION_DISTINCT,
      block_subs: proceedUserLike(data.userLike),
    })
  }

  if (isUserDidVerified(data)) {
    requestPayload.push({
      block_id: 2,
      block_type: data.userDid.operator === operators.AND ? BLOCK_TYPE.INTERSECT_DISTINCT : BLOCK_TYPE.UNION_DISTINCT,
      block_subs: proceedUserDid(data.userDid),
    })
  }

  if (isUserDidNotVerified(data)) {
    requestPayload.push({
      block_id: 3,
      block_type: BLOCK_TYPE.INTERSECT_DISTINCT,
      block_subs: proceedUserDidNot(data.didNotDo),
    })
  }

  if (isDisplayCommonVerified(data)) {
    requestPayload.push({
      block_id: 4,
      block_type: BLOCK_TYPE.INTERSECT_DISTINCT,
      block_subs: proceedDisplayCommonProperties(data.displayCommonProperties),
    })
  }

  return {
    set_operations: SET_OPERATIONS,
    blocks: requestPayload,
  }
}
