import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';

import Input from './input';

import './index.scss';
import {useEffect} from "react";

export default function DidNotDo({
                                   name,
                                   defaultValue = [],
                                 }) {
  const {t} = useTranslation();
  const {control} = useFormContext();
  const {field} = useController({name, control, defaultValue});

  const onChange = (value) => {
    field.onChange(value);
  };

  useEffect(() => {
    onChange(defaultValue);
  }, [JSON.stringify(defaultValue)]);

  return (
    <div id="display-common-properties" className="grid grid-cols-1 gap-2">
      <div>
        <span className='ds-text-form-title mr-1'>{t('DISPLAY_COMMON_PROPERTIES_SUCH_AS')}</span>
        <span className='ds-text-lighter'>({t('OPTIONAL')})</span>
      </div>
      <div>
        <Input onChange={onChange} value={field.value}/>
      </div>
    </div>
  );
}
