import {useEffect, useMemo, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan} from '@fortawesome/free-solid-svg-icons';

import {isArray, isUndefinedOrEmpty} from 'utils';
import {CHART_CONFIG, DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT} from 'consts';
import {hourValue, whereValue} from 'consts/chartConfig';

import DateFilter from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';
import MultiSelectOption from 'components/MultiSelectOption/WithoutForm';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import {operatorValue, typeValue} from './types';

import './index.scss';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const operatorOptions = [
  {
    text: 'contains',
    value: operatorValue.CONTAINS,
  },
  {
    text: 'equals',
    value: operatorValue.EQUALS,
  },
];

const typeOptions = [
  {
    text: 'Event Property',
    value: typeValue.EVENT_PROPERTY,
  },
  {
    text: 'Time of the day',
    value: typeValue.TIME_OF_THE_DAY,
  },
  {
    text: 'Day of the week',
    value: typeValue.DAY_OF_THE_WEEK,
  }
];

const atLeastOptions = Array.from({length: 20}, (_, index) => (index + 1) * 5).map((i) => (
  {
    text: i,
    value: i
  }
))

export default function Form({
                               value = {
                                 type: '',
                                 paramEventName: '',
                                 paramDate: [
                                   moment().subtract(2, 'days').format(DATE_FORMAT),
                                   moment().add(2, 'days').format(DATE_FORMAT)
                                 ],

                                 paramFrequency: '',
                                 paramFrequencyAtLeastValue: 5,
                                 paramEventProperty: {
                                   name: "",
                                   operator: "=",
                                   value: ""
                                 }
                               },
                               onChange = () => {
                               },
                               onDelete = () => {
                               },
                               paramEventNameOptions = [],
                               eventPropertyOptions = [],
                               onEventNameChange = () => {
                               },
                               className,
                             }) {
  const {t} = useTranslation();
  const wrapper = useRef(null);
  const dateWrapper = useRef(null);

  const isShowAtLeast = useMemo(
    () => value.paramFrequency === whereValue.AT_LEAST
    , [value.paramFrequency]);

  // useEffect(() => {
  //   if (eventPropertyOptions.length > 0) {
  //     onChange({
  //       ...value,
  //       paramEventProperty: eventPropertyOptions[0].value
  //     })
  //   }
  // }, []);

  const handleSelectTypeChange = (fieldValue) => {
    const newValue = {
      ...value,
      type: fieldValue,
    };

    if (newValue.type === typeValue.EVENT_PROPERTY) {
      delete newValue.dayOfWeek;
      delete newValue.paramHour;

      newValue.paramEventProperty = {
        name: '',
        operator: operatorValue.EQUALS,
        value: '',
      }
    }

    if (newValue.type === typeValue.TIME_OF_THE_DAY) {
      delete newValue.dayOfWeek;
      delete newValue.paramEventProperty;

      newValue.paramHour = [hourValue['6_PM'], hourValue['7_PM']];
    }

    if (newValue.type === typeValue.DAY_OF_THE_WEEK) {
      delete newValue.paramHour;
      delete newValue.paramEventProperty;

      newValue.dayOfWeek = [];
    }

    onChange(newValue);
  };

  const handleSelectEventNameChange = (fieldValue) => {
    onChange({
      ...value,
      paramEventName: fieldValue,
    });
    onEventNameChange(fieldValue);
  };

  const handleSelectFrequencyAtLeastChange = (fieldValue) => {
    onChange({
      ...value,
      paramFrequencyAtLeastValue: parseInt(fieldValue),
    });
  };

  const handleSelectWhereChange = (fieldValue) => {
    onChange({
      ...value,
      paramFrequency: fieldValue,
    });
  };

  const handleSelectHavingNameChange = (fieldValue) => {
    onChange({
      ...value,
      paramEventProperty: {
        ...value.paramEventProperty,
        name: fieldValue,
      },
    });
  };

  const handleSelectHavingOperatorChange = (fieldValue) => {
    onChange({
      ...value,
      paramEventProperty: {
        ...value.paramEventProperty,
        operator: fieldValue,
      }
    });
  };

  const handleHavingValueChange = (event) => {
    onChange({
      ...value,
      paramEventProperty: {
        ...value.paramEventProperty,
        value: event.target.value,
      }
    });
  };

  const handleSelectHourFirstValueChange = (fieldValue) => {
    onChange({
      ...value,
      paramHour: [fieldValue, value.paramHour[1]]
    });
  };

  const handleSelectHourSecondValueChange = (fieldValue) => {
    onChange({
      ...value,
      paramHour: [value.paramHour[0], fieldValue]
    });
  };

  const handleSelectOnChange = (newValue) => {
    onChange({
      ...value,
      dayOfWeek: newValue,
    });
  };

  const onDateChange = (newValue) => {
    onChange({
      ...value,
      paramDate: newValue,
    });
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  useEffect(() => {
    if (isArray(paramEventNameOptions) && paramEventNameOptions.length > 0 && isUndefinedOrEmpty(value.paramEventName)) {
      onChange({
        ...value,
        paramEventName: paramEventNameOptions[0].value,
      });

      onEventNameChange(paramEventNameOptions[0].value);
    }
  }, [paramEventNameOptions]);

  useEffect(() => {
    if (isArray(eventPropertyOptions) && eventPropertyOptions.length > 0 && !value.paramEventProperty.name) {
      onChange({
        ...value,
        paramEventProperty: {
          ...value.paramEventProperty,
          name: eventPropertyOptions[0].value,
        },
      });
    }
  }, [eventPropertyOptions]);

  useEffect(() => {
    onEventNameChange(value.paramEventName)
  }, [value.paramEventName]);

  const renderLastInput = () => {
    if (value.type === typeValue.EVENT_PROPERTY) {
      return (
        <>
          <span className='lowercase'>{t('HAVING_EVENT_PROPERTY_VALUE')}</span>

          <TailwindComboboxWithoutForm items={eventPropertyOptions} onChange={handleSelectHavingNameChange} value={value.paramEventProperty.name}/>

          <TailwindComboboxWithoutForm items={operatorOptions} onChange={handleSelectHavingOperatorChange} value={value.paramEventProperty.operator}/>

          <input
            value={value.paramEventProperty.value}
            onChange={handleHavingValueChange}
            className="ds-input"
          />
        </>
      )
    }

    if (value.type === typeValue.TIME_OF_THE_DAY) {
      return (
        <>
          <span className='lowercase'>{t('DURING')}</span>

          <TailwindComboboxWithoutForm items={CHART_CONFIG.HOUR_OPTIONS} onChange={handleSelectHourFirstValueChange}/>

          <span className='lowercase ms-1'>{t('TO')}</span>

          <TailwindComboboxWithoutForm items={CHART_CONFIG.HOUR_OPTIONS} onChange={handleSelectHourSecondValueChange}/>
        </>
      )
    }

    if (value.type === typeValue.DAY_OF_THE_WEEK) {
      return (
        <>
          <span className='lowercase ms-1 me-1'>{t('ON')}</span>

          <MultiSelectOption
            value={value.dayOfWeek}
            onChange={handleSelectOnChange}
            options={CHART_CONFIG.DAY_OF_WEEK_OPTIONS}
            className={"ds-input"}
          />
        </>
      )
    }
  };

  return (
    <div className={`relative grid grid-cols-1 gap-2 mb-3 ds-block ${className}`}>
      <FontAwesomeIcon onClick={onDelete} className='absolute text-red-500 right-2 top-6 cursor-pointer'
                       icon={faTrashCan}/>

      {/*Event Properties*/}
      <div className='flex items-center justify-start gap-4'>
        <div>
          <TailwindComboboxWithoutForm items={typeOptions} onChange={handleSelectTypeChange}/>
        </div>
        <div>
          <span>{t('for')}</span>
        </div>
        <div>
          <TailwindComboboxWithoutForm items={paramEventNameOptions} onChange={handleSelectEventNameChange} value={value.paramEventName}/>
        </div>
        <div>
          <ToggleBox ref={wrapper} onClose={onClose}>
            <ToggleBox.Button>
              <div className="ds-input">
                <span>{moment(value.paramDate[0]).format(DISPLAY_DATE_CALENDAR_FORMAT)} - {moment(value.paramDate[1]).format(DISPLAY_DATE_CALENDAR_FORMAT)}</span>
              </div>
            </ToggleBox.Button>

            <ToggleBox.Content>
              <DateFilter ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter} value={value.paramDate}/>
            </ToggleBox.Content>
          </ToggleBox>
        </div>
      </div>

      {/*where it occurs*/}
      {/*<div className='flex items-center justify-start gap-4'>*/}
      {/*  <span className='lowercase'>{t('WHERE_IT_OCCURS')}</span>*/}

      {/*  <TailwindComboboxWithoutForm items={CHART_CONFIG.FREQUENCY_OPTIONS} onChange={handleSelectWhereChange}/>*/}

      {/*  {isShowAtLeast && (*/}

      {/*    <>*/}

      {/*      <TailwindComboboxWithoutForm items={atLeastOptions} onChange={handleSelectFrequencyAtLeastChange}/>*/}

      {/*      <span className='lowercase ms-2'>{t('%_OF_TIME')}</span>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*having event property value*/}
      <div className='flex items-center justify-start gap-4'>
        {renderLastInput()}
      </div>
    </div>
  );
}
