import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import {debounce} from 'lodash';
import {CHART_CONFIG} from "consts";

import ChartSerivce from 'services/chart';

import {parseLineChartResponse} from '../chartProcessor';
import VisualisationEmpty from "../visualisation_empty";
import {formatNumber, isUndefinedOrEmpty} from "utils";
import {DS_CHART_COLORS} from "../consts";
import {titleCase} from "utils/util";

const buildChartData = (key, categories, series) => {
  return {
    key: key,
    series: series,
    options: {
      chart: {
        zoom: {
          enabled: false,
        },
        height: 350,
        type: 'line'
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      colors: DS_CHART_COLORS,
      xaxis: {
        type: 'category',
        categories: categories,
        tickAmount: categories.length - 1,
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return formatNumber(Math.round(value * 10) / 10);
          }
        }
      },
      tooltip: {
        shared: true,
        intersect: false,
        inverseOrder: true,
        hideEmptySeries: true,
        // custom:
        //   function({ series, seriesIndex, dataPointIndex, w }) {
        //     if (series[seriesIndex][dataPointIndex] !== null) {
        //       return '<div class="tooltip">' +
        //         '<span>' + w.globals.labels[dataPointIndex] + '</span>' +
        //         '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
        //         '</div>';
        //     }
        //     return '';
        //   }
      },
    },
  }
};


export default function VisualisationLineChart({
                                                 appId,
                                                 globalFilter = [],
                                                 chartId,
                                                 dimensionValue,
                                                 metricValue,
                                                 showNonZero,
                                                 frequencyValue,
                                                 title
                                               }) {
  const {t} = useTranslation();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);


  const charts = useMemo(() => {
    const charts = [];
    data.map((v) => {
      let categories = [];
      let series = [];

      Object.keys(v).map((f) => {
        if (f === "date_key") {
          categories = v[f];  // Assign categories to date_key values
        } else {
          v[f].map((e) => {
            series.push(e);  // Add other values to the series
          });
        }
      });
      charts.push(buildChartData(Object.keys(v).find(key => key !== "date_key"), categories, series));  // Build chart data after iteration
    });

    return charts
  }, [data]);

  const getData = async () => {
    if ((dimensionValue && metricValue && globalFilter.length > 0)) {
      const request = {
        [CHART_CONFIG.FIELD_CHART_NAME]: chartId,
        [CHART_CONFIG.FIELD_CHART_TYPE]: CHART_CONFIG.CHART_TYPE_LINECHART,
        [CHART_CONFIG.FIELD_GLOBAL_FILTERS]: globalFilter,
        [CHART_CONFIG.FIELD_METRIC]: [],
        [CHART_CONFIG.FIELD_GROUP_BY]: [],
        [CHART_CONFIG.FIELD_ORDER_BY]: [{[CHART_CONFIG.FIELD_NAME]: CHART_CONFIG.CHART_DATE_FIELD}]
      };

      if (!isUndefinedOrEmpty(frequencyValue)) {
        request[CHART_CONFIG.FIELD_GROUP_BY].push(
          {
            [CHART_CONFIG.FIELD_NAME]: frequencyValue,
            [CHART_CONFIG.FIELD_VALUE]: CHART_CONFIG.CHART_DATE_FIELD,

          }
        )
      } else {
        request[CHART_CONFIG.FIELD_GROUP_BY].push(
          {[CHART_CONFIG.FIELD_NAME]: CHART_CONFIG.CHART_DATE_FIELD}
        )
      }

      const dimensions = Array.isArray(dimensionValue) ? dimensionValue : [dimensionValue];
      dimensions.map((v) => {
          if (v !== CHART_CONFIG.CHART_DATE_FIELD){
            request[CHART_CONFIG.FIELD_GROUP_BY].push({[CHART_CONFIG.FIELD_NAME]: v})
          }
        })
      const metrics = Array.isArray(metricValue) ? metricValue : [metricValue];
      request[CHART_CONFIG.FIELD_METRIC] = metrics.map(metric => ({[CHART_CONFIG.FIELD_NAME]: metric}));


      setIsLoading(true);
      const result = await ChartSerivce.getChart(appId, request);

      if (result) {
        setData(parseLineChartResponse({
          responsePayload: result.data,
          showNonZeroOnly: showNonZero,
        }));
      } else {
        setData([]);
      }
      setIsLoading(false);
    }
  };

  const debouncedGetData = debounce(getData, 500);

  useEffect(() => {
    debouncedGetData();
  }, [globalFilter, JSON.stringify(dimensionValue), JSON.stringify(metricValue), showNonZero]);

  return (
    <div className="w-full">
      <div className="space-y-4 pt-4">
        {
          charts.length > 0 ? charts.map((v, index) => (
            <div className={"space-y-2"} key={index}>
              {title ? <div className="ds-text-page-sub-title">{titleCase(v.key)}</div> : ''}
              <div className={"ds-white-background"}>
                <ReactApexChart options={v.options} series={v.series} type="line" height={350}/>
              </div>
            </div>
          )) : <VisualisationEmpty isLoading={isLoading}/>
        }
      </div>
    </div>
  )
}
