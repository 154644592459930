import {useEffect} from 'react';
import {isArray, isUndefinedOrEmpty} from 'utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {BETWEEN, CONTAINS, IN} from 'consts/chartConfig';

import MultiSelectOption from 'components/MultiSelectOption/WithoutForm';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import PropertyInput from './PropertyInput';
import TimeInput from './TimeInput';

import types from './types';


const dayOfWeeksOptions = [
  {
    text: 'Sunday',
    value: 'sunday',
  },
  {
    text: 'Monday',
    value: 'monday',
  },
  {
    text: 'Tuesday',
    value: 'tuesday',
  },
  {
    text: 'Wednesday',
    value: 'wednesday',
  },
  {
    text: 'Thursday',
    value: 'thursday',
  },
  {
    text: 'Friday',
    value: 'friday',
  },
  {
    text: 'Saturday',
    value: 'saturday',
  },
];


const ordinalNumbers = Array.from({length: 31}, (_, index) => {
  const number = index + 1;
  let suffix;

  // Determine the suffix based on the rules for ordinal numbers
  if (number % 100 >= 11 && number % 100 <= 13) {
    suffix = 'th';
  } else {
    switch (number % 10) {
      case 1:
        suffix = 'st';
        break;
      case 2:
        suffix = 'nd';
        break;
      case 3:
        suffix = 'rd';
        break;
      default:
        suffix = 'th';
    }
  }

  return {
    text: `${number}${suffix}`,
    value: number,
  };
});

/**
 * Component for rendering a segment filter input.
 * @param {Object} props - The props object.
 * @param {Object} [props.value={ type: types.EVENT_PROPERTY, value: {} }] - The value of the segment filter input.
 * @param {Function} [props.onChange=() => {}] - The function to handle value changes.
 * @param {Function} [props.onDelete=() => {}] - The function to handle deletion of the segment filter input.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @returns {JSX.Element} A React component representing the SegmentFilterInput.
 */
export default function SegmentFilterInput({
                                             value = {
                                               type: types.EVENT_PROPERTY,
                                               value: {},
                                             },
                                             eventPropertyOptions = [],
                                             onChange = () => {
                                             },
                                             onDelete = () => {
                                             },
                                             typeOptions = [
                                               {
                                                 text: 'Event property',
                                                 value: types.EVENT_PROPERTY,
                                               },
                                               {
                                                 text: 'First name',
                                                 value: types.FIRST_NAME,
                                               },
                                               {
                                                 text: 'Last name',
                                                 value: types.LAST_NAME,
                                               },
                                               {
                                                 text: 'Time of the day',
                                                 value: types.TIME_OF_THE_DAY,
                                               },
                                               {
                                                 text: 'Day of the week',
                                                 value: types.DAY_OF_THE_WEEK,
                                               },
                                               {
                                                 text: 'Day of the month',
                                                 value: types.DAY_OF_THE_MONTH,
                                               },
                                             ],
                                             className,
                                           }) {

  const handleSelectTypeChange = (fieldValue) => {
    const newValue = {...value};

    newValue.type = fieldValue;

    if (newValue.type === types.EVENT_PROPERTY) {
      const property = isArray(eventPropertyOptions) && eventPropertyOptions.length > 0 ? eventPropertyOptions[0].value : "";

      newValue.value = {
        property: property,
        operator: CONTAINS,
        value: "",
      };
    }

    if (newValue.type === types.FIRST_NAME) {
      newValue.value = [];
      newValue.operator = IN;
    }

    if (newValue.type === types.LAST_NAME) {
      newValue.value = [];
      newValue.operator = IN;
    }

    if (newValue.type === types.TIME_OF_THE_DAY) {
      newValue.value = [0, 23];
      newValue.operator = BETWEEN;
    }

    if (newValue.type === types.DAY_OF_THE_WEEK) {
      newValue.value = [];
      newValue.operator = IN;
    }

    if (newValue.type === types.DAY_OF_THE_MONTH) {
      newValue.value = [];
      newValue.operator = IN;
    }

    onChange(newValue);
  };

  /**
   * Handles the change of the property value.
   * @param {Object} newValue - The new value of the property.
   */
  const onPropertyChange = (newValue) => {
    onChange({
      ...value,
      value: {
        ...newValue,
      },
    });
  };

  /**
   * Handles the change of the value.
   * @param {Object} newValue - The new value.
   */
  const onValueChange = (newValue) => {
    onChange({
      ...value,
      value: newValue,
    })
  };

  useEffect(() => {
    const conditions = [
      value.type === types.EVENT_PROPERTY,
      isArray(eventPropertyOptions),
      eventPropertyOptions.length > 0,
      isUndefinedOrEmpty(value.value.property)
    ];

    if (conditions.every(e => e === true)) {
      onChange({
        type: types.EVENT_PROPERTY,
        value: {
          ...value.value,
          property: eventPropertyOptions[0].value,
        },
      });
    }
  }, [eventPropertyOptions]);

  const renderComponent = (eventType) => {
    switch (eventType) {
      case types.EVENT_PROPERTY:
        return <PropertyInput eventPropertyOptions={eventPropertyOptions} value={value.value}
                              onChange={onPropertyChange}/>
      case types.FIRST_NAME:
        return <MultiSelectOption
          value={value.value}
          onChange={onValueChange}
          options={[{text: 'yes', value: 'yes'}]}
        />
      case types.LAST_NAME:
        return <MultiSelectOption
          value={value.value}
          onChange={onValueChange}
          options={[{text: 'yes', value: 'yes'}]}
        />
      case types.TIME_OF_THE_DAY:
        return <TimeInput value={value.value} onChange={onValueChange}/>
      case types.DAY_OF_THE_WEEK:
        return <MultiSelectOption
          value={value.value}
          onChange={onValueChange}
          options={dayOfWeeksOptions}
        />
      case types.DAY_OF_THE_MONTH:
        return <MultiSelectOption
          value={value.value}
          onChange={onValueChange}
          options={ordinalNumbers}
        />
      default:
        return ''
    }

  }

  return (
    <div className={`flex justify-start items-center gap-4 ${className}`}>
      <div>
        <TailwindComboboxWithoutForm className={"ds-violet-border"} value={value.type} items={typeOptions} onChange={handleSelectTypeChange}/>
      </div>
      <div>
        {renderComponent(value.type)}
      </div>
      <FontAwesomeIcon onClick={onDelete} className='text-red-500 cursor-pointer ms-2' icon={faTrashCan}/>
    </div>
  );
};
