import { useTranslation } from "react-i18next";
import moment from 'moment';
import { DATE_FORMAT } from 'consts';

import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import { operators, anyOperators } from './types';
import Form from './Form';
import { whereTypes, whereOperators } from './Form/types';

const OPERATOR_OPTIONS = [
  {
    text: 'All (AND)',
    value: operators.AND,
  },
  {
    text: 'Any (OR)',
    value: operators.OR,
  },
];

const ANY_OPERATOR_OPTIONS = [
  {
    text: '≥ (greater or equals)',
    value: anyOperators.GREATER_OR_EQUALS,
  },
  {
    text: '= (equals)',
    value: anyOperators.EQUALS,
  },
];

export default function Input({
  value = {
    operator: "AND",
    value: []
  },
  onChange = () => { },
}) {
  const { t } = useTranslation();

  const handleSelectOperatorChange = (fieldValue) => {
    const newValue = {
      ...value,
      operator: fieldValue,
    };

    if (fieldValue === operators.AND) {
      delete newValue.condition;
    } else {
      newValue.condition = {
        operator: anyOperators.GREATER_OR_EQUALS,
        value: 1,
      };
    }

    onChange(newValue);
  };

  const handleSelectConditionOperatorChange = (fieldValue) => {
    onChange({
      ...value,
      condition: {
        ...value.condition,
        operator: fieldValue,
      },
    });
  };

  const handleConditionValueChange = (event) => {
    onChange({
      ...value,
      condition: {
        ...value.condition,
        value: event.target.value,
      },
    });
  };

  const addData = () => {
    const data = { ...value };

    data.value.push({
      paramEventName: '',
      paramDate: [
        moment().subtract(2, 'days').format(DATE_FORMAT),
        moment().add(2, 'days').format(DATE_FORMAT)
      ],
      paramFilter: [],
      paramWhere: {
        type: whereTypes.COUNT,
        name: '',
        operator: whereOperators.GREATER_OR_EQUALS,
        value: 1,
      }
    });

    onChange(data);
  };

  const deleteData = (index) => {
    const data = { ...value };

    data.value.splice(index, 1);

    onChange(data);
  };

  const setDataForm = (index, newData) => {
    const data = { ...value };

    data.value[index] = newData;

    onChange(data);
  };


  return (
    <div className={"relative grid gird-cols-1"}>
      {/*And of these events*/}
      <div className="flex items-center justify-start gap-4">
        {
          value.value.length > 0 &&
          <>
            <div>
              <TailwindComboboxWithoutForm items={OPERATOR_OPTIONS} onChange={handleSelectOperatorChange}/>
            </div>
            <div>
              <span> {value.operator === operators.AND ? t('OF_THESE_EVENTS') : t('COMBINATION_OF_THESE_EVENTS')}</span>
            </div>
            {
              value.operator === operators.OR &&
              <>
                <div>
                  <TailwindComboboxWithoutForm items={ANY_OPERATOR_OPTIONS} onChange={handleSelectConditionOperatorChange}/>
                </div>
                <div>
                  <input
                    value={value.condition.value}
                    onChange={handleConditionValueChange}
                    type='number'
                    className="ds-input"
                  />
                </div>
                <div>
                  <span> {t('TIMES')}</span>
                </div>
              </>
            }
          </>
        }
      </div>

      {/*input & date*/}
      {value.value.map((item, index) => (
        <div className="flex items-center justify-start gap-4">
          <Form
            key={index}
            value={item}
            showWhereCondition={value.operator === operators.AND}
            onChange={(data) => setDataForm(index, data)}
            onDelete={() => deleteData(index)}
            className="mt-1 ds-block"
          />
        </div>
      ))}

      <div onClick={addData}
           className="ds-button-2">
        + {t("EVENT")}
      </div>
    </div>
  )
}
