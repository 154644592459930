// import React from 'react';

// export const Faqs = () => {
//   return (
//     <div className="max-w-7xl mx-auto px-12 pt-12 pb-12 glass-effect">
//       <h2 className="text-violet-500 font-semibold text-5xl px-12 py-0 lg-py-0 mb-4">
//         Frequently Ask Questions
//       </h2>

//       <div className="flex flex-col gap-8 mx-auto px-4 py-4">
//         <div className="flex-1 px-6 py-6 faq-box-effect">
// <p className="text-slate-700 font-semibold mb-2 text-lg">
// How can DataSenses enhance your business?
// </p>
//           <p className="text-slate-700 text-lg">
//           DataSenses empowers you to gain deeper insights into your marketing campaigns and customer behaviors, enabling more informed decision-making.
//           </p>
//         </div>

//         <div className="flex-1 px-6 py-6 faq-box-effect">
//           <p className="text-slate-700 font-semibold mb-2 text-lg">
//           How does DataSenses optimize advertising efforts using data?
//           </p>
//           <p className="text-slate-700 text-lg">
//           DataSenses integrates with AdNetworks to sync crucial data, facilitating ad optimization. We also analyze customer journeys to provide a comprehensive understanding of your audience.
//           </p>
//         </div>
//         <div className="flex-1 px-6 py-6 faq-box-effect">
//           <p className="text-slate-700 font-semibold text-lg mb-2">
//           How is Return On Investment (ROI) calculated with DataSenses?
//           </p>
//           <p className="text-slate-700 text-lg">
//           We monitor and attribute conversions from installations or purchases to specific campaigns, whether direct or advertised. This data is then used to map insights and calculate the ROI of your marketing efforts.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Faqs;

import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export function Faqs() {
  return (
    <div className="max-w-7xl mx-auto glass-effect text-left lg:p-16 p-5">
      <h2 className="text-violet-500 font-semibold text-3xl lg:text-5xl pb-10 lg-pb-10 ">
        Frequently Asked Questions
      </h2>

      <div className={"grid grid-rows-1 gap-4 text-base lg:text-lg"}>
        <div className="flex-1">
          <Disclosure>
            <DisclosureButton className="text-left">
              <p className="text-slate-700 font-semibold mb-2 hover:text-violet-500 ">
                How can DataSenses enhance your business?
              </p>
            </DisclosureButton>
            <DisclosurePanel className="text-gray-500">
              <p className="text-slate-700">
                DataSenses empowers you to gain deeper insights into your
                marketing campaigns and customer behaviors, enabling more informed
                decision-making.
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
        <div className="flex-1">
          <Disclosure>
            <DisclosureButton className="text-left">
              <p className="text-slate-700 font-semibold mb-2 hover:text-violet-500">
                How does DataSenses optimize advertising efforts using data?
              </p>
            </DisclosureButton>
            <DisclosurePanel className="text-gray-500">
              <p className="text-slate-700">
                DataSenses integrates with AdNetworks to sync crucial data,
                facilitating ad optimization. We also analyze customer journeys to
                provide a comprehensive understanding of your audience.
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
        <div className="flex-1">
          <Disclosure>
            <DisclosureButton className="text-left">
              <p className="text-slate-700 font-semibold mb-2 hover:text-violet-500">
                How is Return On Investment (ROI) calculated with DataSenses?
              </p>
            </DisclosureButton>
            <DisclosurePanel className="text-gray-500">
              <p className="text-slate-700 text-lg">
                We monitor and attribute conversions from installations or
                purchases to specific campaigns, whether direct or advertised.
                This data is then used to map insights and calculate the ROI of
                your marketing efforts.
              </p>
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>

      {/*<div className="flex-1 px-12 pb-4">*/}
      {/*  <Disclosure>*/}
      {/*    <DisclosureButton className="text-left">*/}
      {/*      <p className="text-slate-700 font-semibold mb-2 text-lg hover:text-violet-500 ">*/}
      {/*        How can DataSenses enhance your business?*/}
      {/*      </p>*/}
      {/*    </DisclosureButton>*/}
      {/*    <DisclosurePanel className="text-gray-500">*/}
      {/*      <p className="text-slate-700 text-lg">*/}
      {/*        DataSenses empowers you to gain deeper insights into your*/}
      {/*        marketing campaigns and customer behaviors, enabling more informed*/}
      {/*        decision-making.*/}
      {/*      </p>*/}
      {/*    </DisclosurePanel>*/}
      {/*  </Disclosure>*/}
      {/*</div>*/}

      {/*<div className="flex-1 px-12 pb-4">*/}
      {/*  <Disclosure>*/}
      {/*    <DisclosureButton className="text-left">*/}
      {/*      <p className="text-slate-700 font-semibold mb-2 text-lg hover:text-violet-500">*/}
      {/*        How does DataSenses optimize advertising efforts using data?*/}
      {/*      </p>*/}
      {/*    </DisclosureButton>*/}
      {/*    <DisclosurePanel className="text-gray-500">*/}
      {/*      <p className="text-slate-700 text-lg">*/}
      {/*        DataSenses integrates with AdNetworks to sync crucial data,*/}
      {/*        facilitating ad optimization. We also analyze customer journeys to*/}
      {/*        provide a comprehensive understanding of your audience.*/}
      {/*      </p>*/}
      {/*    </DisclosurePanel>*/}
      {/*  </Disclosure>*/}
      {/*</div>*/}

      {/*<div className="flex-1 px-12">*/}
      {/*  <Disclosure>*/}
      {/*    <DisclosureButton className="text-left">*/}
      {/*      <p className="text-slate-700 font-semibold mb-2 text-lg hover:text-violet-500">*/}
      {/*        How is Return On Investment (ROI) calculated with DataSenses?*/}
      {/*      </p>*/}
      {/*    </DisclosureButton>*/}
      {/*    <DisclosurePanel className="text-gray-500">*/}
      {/*      <p className="text-slate-700 text-lg">*/}
      {/*        We monitor and attribute conversions from installations or*/}
      {/*        purchases to specific campaigns, whether direct or advertised.*/}
      {/*        This data is then used to map insights and calculate the ROI of*/}
      {/*        your marketing efforts.*/}
      {/*      </p>*/}
      {/*    </DisclosurePanel>*/}
      {/*  </Disclosure>*/}
      {/*</div>*/}
    </div>
  );
}

export default Faqs;
