import HttpMethod from "consts/httpMethod";
import { processErrorResponse, processRequest, processSuccessResponse, stringify } from "utils";

export default {
  async createDashboard({name}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/dashboard',
      method: HttpMethod.POST
    };

    try {
      const res = await processRequest(ep, { data: {name} });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return false;
    }
  },

  async getDashboardDetail({ dashboardId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/dashboard/${dashboardId}`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async listDashboards(query = {}) {
    const queryParams = stringify(query);

    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/dashboard/list${queryParams ? `?${queryParams}` : ''}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async getDashboardListFromAppId({ appId, query }) {
    const queryParams = stringify(query);

    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/dashboard/list/${appId}${queryParams ? `?${queryParams}` : ''}`,
      method: HttpMethod.GET
    };

    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async updateDashboard({ dashboardId, name }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/dashboard/${dashboardId}/update',
      method: HttpMethod.PUT
    };
    try {
      const res = await processRequest(ep, { data: { dashboardId, name } });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async addToDashboard({ name, type, subType, payload, existingDashboardIds, newDashboardName }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/card/add-to-dashboard',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, { data: { name, type, subType, payload, existingDashboardIds, newDashboardName } });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async removeChart({ dashboardId, chartId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/dashboard/chart/remove',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, { data: { dashboardId, chartId } });
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
  async deleteDashboard({ dashboardId }) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/dashboard/${dashboardId}/delete`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep);
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
}
