import { Fragment } from 'react'
import { CheckIcon, MinusIcon } from '@heroicons/react/20/solid'
import company from "services/company";



const companyTiers = [
  {
    name: 'DataSenses',
    id: 'company-datasenses',
    priceMonthly: '',
    description: 'Good for anyone who is self-employed and just getting started',
    mostPopular: false,
  },
  {
    name: 'Appsflyer',
    id: 'company-appsflyer',
    priceMonthly: '  ',
    description: 'Perfect for small / medium sized businesses',
    mostPopular: true,
  },
  {
    name: 'Adjust',
    id: 'company-adjust',
    priceMonthly: '  ',
    description: 'For even the biggest enterprise companies.',
    mostPopular: false,
  },
]

const companySections = [
  {
    features: [
      { name: 'Price', tiers: { DataSenses: 'Cheapest', Appsflyer: 'Higher', Adjust: 'Higher' } },
      { name: 'App tracking', tiers: { DataSenses: true, Appsflyer: true, Adjust: true } },
      { name: 'Web tracking', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'ROI 360', tiers: { DataSenses: 'Free', Appsflyer: 'Paid', Adjust: 'Paid' } },
      { name: 'Install attribution', tiers: { DataSenses: true, Appsflyer: true, Adjust: true } },
      { name: 'Ecommerce Order attribution', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'Affiliate KOL attribution', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'Deep link solution', tiers: { DataSenses: true, Appsflyer: true, Adjust: true } },
      { name: 'Online2Offline solution', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'Advertising Integration', tiers: { DataSenses: '6', Appsflyer: '> 30', Adjust: '> 30' } },
      { name: 'VN Ecommerce Integration', tiers: { DataSenses: '5', Appsflyer: '0', Adjust: '0' } },
      { name: 'Sync audience', tiers: { DataSenses: 'Free', Appsflyer: 'Paid', Adjust: false } },
      { name: 'DataSenses BI Tool', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'Audience Segmentation', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'Cohort Analysis', tiers: { DataSenses: true, Appsflyer: true, Adjust: true } },
      { name: 'Funnel Analysis', tiers: { DataSenses: true, Appsflyer: false, Adjust: false } },
      { name: 'Fraud detection System', tiers: { DataSenses: true, Appsflyer: true, Adjust: true } },
    ],
  },
]

const tiers = [
  {
    name: 'Free',
    id: 'tier-basic',
    href: '/request-demo',
    priceMonthly: '',
    description: 'Good for anyone who is self-employed and just getting started',
    mostPopular: false,
  },
  {
    name: 'Growth',
    id: 'tier-essential',
    href: '/request-demo',
    priceMonthly: '  ',
    description: 'Perfect for small / medium sized businesses',
    mostPopular: true,
  },
  {
    name: 'Enterprise',
    id: 'tier-premium',
    href: '/request-demo',
    priceMonthly: '  ',
    description: 'For even the biggest enterprise companies.',
    mostPopular: false,
  },
]

const sections = [
  {
    name: 'Features',
    features: [
      { name: 'App / Web tracking', tiers: { Free: true, Growth: true, Enterprise: true } },
      { name: 'ROI 360', tiers: { Free: "Up to 1000 attributions", Growth: "Up to 50000 attributions", Enterprise: 'Unlimited' } },
      { name: 'Install attribution', tiers: { Free: "Up to 1000 attributions", Growth: "Up to 50000 attributions", Enterprise: 'Unlimited' } },
      { name: 'Ecommerce Order attribution', tiers: { Free: "Up to 1000 attributions", Growth: "Up to 50000 attributions", Enterprise: 'Unlimited' } },
      { name: 'Affiliate KOL attribution', tiers: { Free: "Up to 1000 attributions", Growth: "Up to 50000 attributions", Enterprise: 'Unlimited' } },
      { name: 'Deep link solution', tiers: { Free: true, Growth: true, Enterprise: true } },
      { name: 'Online2Offline solution', tiers: { Free: false, Growth: false, Enterprise: true } },
      { name: 'Fraud detection system', tiers: { Free: false, Growth: false, Enterprise: true } },
      { name: 'Sync audience', tiers: { Free: false, Growth: false, Enterprise: true } },
      { name: 'DataSenses BI Tool', tiers: { Free: false, Growth: true, Enterprise: true } },
      { name: 'Audience Segmentation', tiers: { Free: false, Growth: true, Enterprise: true } },
      { name: 'Cohort Analysis', tiers: { Free: false, Growth: true, Enterprise: true } },
      { name: 'Funnel Analysis', tiers: { Free: false, Growth: true, Enterprise: true } },
    ],
  },
  {
    name: 'Reporting',
    features: [
      { name: 'Marketing / Ecommerce cost', tiers: { Free: false, Growth: true, Enterprise: true } },
      { name: 'Funnel Analysis', tiers: { Free: false, Growth: true, Enterprise: true } },
      { name: 'Cohort Analysis', tiers: { Free: false, Growth: true, Enterprise: true } },
      { name: 'Audience Segmentation', tiers: { Free: false, Growth: false, Enterprise: true } },
    ],
  },
  {
    name: 'Support',
    features: [
      { name: '1:1 onboarding tour', tiers: { Free: true, Growth: true, Enterprise: true } },
      { name: 'Priority phone support', tiers: { Free: false, Growth: false, Enterprise: true } },
      { name: 'Quarterly product workshops', tiers: { Free: false, Growth: false, Enterprise: true } },
      { name: '24/7 online support', tiers: { Free: false, Growth: false, Enterprise: true } },
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export function Pricing() {
  return (
    <div>
      <div className={"mx-auto max-w-7xl px-6 lg:px-8"}>
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 lp-text-title-bg-light">
            Features
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl lp-text-desc-bg-light text-center">
          DataSenses is a Growth Analytics Provider. Choose a plan that fits your needs to get started transforming
          insights into growth.
        </p>
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {companyTiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'rounded-xl  glass-effect-2 bg-violet-50/50' : '',
                'p-8'
              )}
            >
              <h3 id={tier.id} className="font-semibold leading-6 text-gray-900">
                {tier.name}
              </h3>
              <ul role="list" className="mt-10 space-y-4 leading-6 text-gray-900">
                {companySections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true"/>
                            <span>
                              {feature.name}{' '}
                              {typeof feature.tiers[tier.name] === 'string' ? (
                                <span className="leading-6 text-gray-500">({feature.tiers[tier.name]})</span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            {companyTiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 0) * 25}%`}}
                >
                  <div className="w-full glass-effect-2 bg-violet-50/50"/>
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4"/>
                <col className="w-1/4"/>
                <col className="w-1/4"/>
                <col className="w-1/4"/>
              </colgroup>
              <thead>
              <tr>
                <td/>
                {companyTiers.map((tier) => (
                  <th key={tier.id} scope="col" className="px-6 pt-6 xl:px-8 xl:pt-8">
                    <div className="font-semibold leading-7 text-gray-900 text-center">{tier.name}</div>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              {companySections.map((section, sectionIdx) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      scope="colgroup"
                      colSpan={4}
                      className={classNames(
                        sectionIdx === 0 ? 'pt-8' : 'pt-16',
                        'pb-4 font-semibold leading-6 text-gray-900'
                      )}
                    >
                      {section.name}
                      <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10"/>
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th scope="row" className="py-4 text-centers font-normal leading-6 text-gray-900">
                        {feature.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"/>
                      </th>
                      {companyTiers.map((tier) => (
                        <td key={tier.id} className="px-6 py-4 xl:px-8">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <div className="text-center text-sm leading-6 text-gray-500">
                              {feature.tiers[tier.name]}
                            </div>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-violet-500" aria-hidden="true"/>
                              ) : (
                                <MinusIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true"/>
                              )}

                              <span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
              </tbody>
            </table>
          </div>
        </div>

      </div>

      {/*-----------------------------------Pricing--------------------------------------*/}

      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-40">
        <div className="mx-auto max-w-4xl text-center">
          <p className="mt-2 lp-text-title-bg-light">
            Plans and pricing
          </p>
        </div>
        {/* xs to lg */}
        <div className="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {tiers.map((tier) => (
            <section
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'rounded-xl  glass-effect-2 bg-violet-50/50' : '',
                'p-8'
              )}
            >
              <h3 id={tier.id} className="text-sm font-semibold leading-6 text-gray-900">
                {tier.name}
              </h3>
              <p className="mt-2 flex items-baseline gap-x-1 text-gray-900">
                <span className="text-4xl font-bold">{tier.priceMonthly}</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? 'bg-violet-500 text-white hover:bg-indigo-500'
                    : 'text-violet-500 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300',
                  'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500'
                )}
              >
                Buy plan
              </a>
              <ul role="list" className="mt-10 space-y-4 text-sm leading-6 text-gray-900">
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" className="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} className="flex gap-x-3">
                            <CheckIcon className="h-6 w-5 flex-none text-violet-500" aria-hidden="true"/>
                            <span>
                              {feature.name}{' '}
                              {typeof feature.tiers[tier.name] === 'string' ? (
                                <span className="text-sm leading-6 text-gray-500">({feature.tiers[tier.name]})</span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div className="isolate mt-20 hidden lg:block">
          <div className="relative -mx-8">
            {tiers.some((tier) => tier.mostPopular) ? (
              <div className="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  className="flex w-1/4 px-4"
                  aria-hidden="true"
                  style={{marginLeft: `${(tiers.findIndex((tier) => tier.mostPopular) + 1) * 25}%`}}
                >
                  <div className="w-full  glass-effect-2 bg-violet-50/50"/>
                </div>
              </div>
            ) : null}
            <table className="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption className="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col className="w-1/4"/>
                <col className="w-1/4"/>
                <col className="w-1/4"/>
                <col className="w-1/4"/>
              </colgroup>
              <thead>
              <tr>
                <td/>
                {tiers.map((tier) => (
                  <th key={tier.id} scope="col" className="px-6 text-center pt-6 xl:px-8 xl:pt-8">
                    <div className="font-semibold leading-7 text-gray-900">{tier.name}</div>
                  </th>
                ))}
              </tr>
              </thead>
              <tbody>
              <tr>
                <th scope="row">
                  <span className="sr-only">Price</span>
                </th>
                {tiers.map((tier) => (
                  <td key={tier.id} className="px-6 pt-2 xl:px-8">
                    <div className="flex items-baseline gap-x-1 text-gray-900">
                      <span className="text-4xl font-bold">{tier.priceMonthly}</span>
                    </div>
                    <a
                      href={tier.href}
                      className={classNames(
                        tier.mostPopular
                          ? 'bg-violet-500 text-white hover:bg-violet-400'
                          : 'text-violet-500 ring-1 ring-inset ring-violet-200 hover:ring-violet-300',
                        'mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-violet-500'
                      )}
                    >
                      Contact Us
                    </a>
                  </td>
                ))}
              </tr>
              {sections.map((section, sectionIdx) => (
                <Fragment key={section.name}>
                  <tr>
                    <th
                      scope="colgroup"
                      colSpan={4}
                      className={classNames(
                        sectionIdx === 0 ? 'pt-8' : 'pt-16',
                        'pb-4 font-semibold leading-6 text-gray-900'
                      )}
                    >
                      {section.name}
                      <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/10"/>
                    </th>
                  </tr>
                  {section.features.map((feature) => (
                    <tr key={feature.name}>
                      <th scope="row" className="py-4 font-normal leading-6 text-gray-900">
                        {feature.name}
                        <div className="absolute inset-x-8 mt-4 h-px bg-gray-900/5"/>
                      </th>
                      {tiers.map((tier) => (
                        <td key={tier.id} className="px-6 py-4 xl:px-8">
                          {typeof feature.tiers[tier.name] === 'string' ? (
                            <div className="text-center text-sm leading-6 text-gray-500">
                              {feature.tiers[tier.name]}
                            </div>
                          ) : (
                            <>
                              {feature.tiers[tier.name] === true ? (
                                <CheckIcon className="mx-auto h-5 w-5 text-violet-500" aria-hidden="true"/>
                              ) : (
                                <MinusIcon className="mx-auto h-5 w-5 text-gray-400" aria-hidden="true"/>
                              )}

                              <span className="sr-only">
                                  {feature.tiers[tier.name] === true ? 'Included' : 'Not included'} in {tier.name}
                                </span>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </Fragment>
              ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>


  )
}
