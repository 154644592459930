export default function TailwindBackground({classNames}) {
  return (
    <div
      // className={`absolute itransform-gpu overflow-hidden blur-3xl ${classNames}`}
      className={`itransform-gpu overflow-hidden blur-3xl ${classNames}`}
      aria-hidden="true"
    >
      <div
        className="relative aspect-[1155/678] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
      />
    </div>
  )
}
