import {useMemo} from "react";
import {useSelector} from 'react-redux';
import {Link} from "react-router-dom";

import {PATHS} from 'consts';

import {DsIconCohort, DsIconFunnel, DsIconPlus} from "components/DsIcon";
import DsIconPieChart from "components/DsIcon/DsIconPieChart";
import DsIconEvents from "components/DsIcon/DsIconEvents";
import DsIconROI from "components/DsIcon/DsIconROI";
import {dsVioletColor} from "consts/color";


export default function VisualisationAddNew({
                                              dimensions, metrics, addNewChart = () => {
  }
                                            }) {
  const appList = useSelector((state) => state.app.appList);

  const defaultAppId = useMemo(() => {
    if (appList.length > 0) {
      return appList[0].appId;
    }
  }, [appList])

  const cardTypeList = [
    {
      name: "Events",
      href: `${PATHS.APP}/${defaultAppId}${PATHS.EVENTS}`,
      icon: <DsIconEvents/>,
    },
    {
      name: "Funnels",
      href: `${PATHS.APP}/${defaultAppId}${PATHS.FUNNEL}`,
      icon: <DsIconFunnel/>,
    },
    {
      name: "Cohorts",
      href: `${PATHS.APP}/${defaultAppId}${PATHS.COHORT}`,
      icon: <DsIconCohort/>,
    },
    {
      name: "Segments",
      href: `${PATHS.APP}/${defaultAppId}${PATHS.FIND_PEOPLE}`,
      icon: <DsIconPieChart/>,
    },
    {
      name: "ROI",
      href: `${PATHS.APP}/${defaultAppId}${PATHS.ROI}`,
      icon: <DsIconROI color={dsVioletColor}/>,
    }
  ]

  return (
    <>
      <div className="flex flex-col items-center justify-center py-20 space-y-6">
        <div>
          <div className="mx-auto h-5 w-5"><DsIconPlus/></div>
          <span className="mt-2 ds-input-text ">Add a new card</span>
        </div>

        <div className="grid grid-cols-3 gap-4">
          {cardTypeList.map((item) => (
            <Link
              to={item.href}
              className="ds-button-card-type flex flex-col items-center justify-center"
            >
              <div className="h-6 w-6 mb-2">
                {item.icon}
              </div>
              {item.name}
            </Link>
          ))}
        </div>
      </div>
    </>
  )
}
