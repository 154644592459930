import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Calendar from 'components/Calendar';
import moment from 'moment';
import {DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT} from 'consts';

import './index.scss';

/**
 * DateFilter component with a toggle box UI.
 * @param {Object} props - The props object.
 * @param {Array} [props.value=[]] - The selected dates.
 * @param {Function} [props.onChange=() => {}] - Function to handle changes in date selection.
 * @param {Function} [props.onClose=() => {}] - Function to handle closing the date filter.
 * @param {React.Ref} ref - Ref to handle imperative methods.
 * @returns {JSX.Element} The JSX representation of the DateFilter component.
 */
export default forwardRef((
  {
    value = [],
    onChange = () => {
    },
    onClose = () => {
    }
  },
  ref
) => {
  const {t} = useTranslation();

  const [defaultDates, setDefaultDates] = useState(value);
  const [fromMonthDate, setFromMonthDate] = useState(moment(defaultDates[0]).startOf('months').subtract(1, 'month').format(DATE_FORMAT));

  const reset = () => {
    setDefaultDates(value);
  };

  useImperativeHandle(ref, () => ({
    reset,
  }));

  const toMonthDate = useMemo(() => {
    if (fromMonthDate) {
      return moment(fromMonthDate).add(1, 'months').startOf('months').format(DATE_FORMAT);
    }
    return fromMonthDate;
  }, [fromMonthDate]);

  const [leftValue, setLeftValue] = useState('');
  const [rightValue, setRightValue] = useState('');

  const onLeftMonthChange = (value) => {
    if (moment(value).isSameOrBefore(moment(toMonthDate))) {
      setFromMonthDate(value);
    }
  };

  const onRightMonthChange = (value) => {
    setFromMonthDate(moment(value).subtract(1, 'months').startOf('months').format(DATE_FORMAT));
  };

  const handleLeftInput = (e) => {
    setLeftValue(e.target.value);
  };

  const handleLeftBlur = () => {
    const value = moment(leftValue);

    if (value.isValid()) {
      if (defaultDates[0] === defaultDates[1]) {
        setDefaultDates([value.format(DATE_FORMAT), value.format(DATE_FORMAT)]);
      } else {
        setDefaultDates([value.format(DATE_FORMAT), defaultDates[1]]);
      }

      setFromMonthDate(value.subtract(1, 'months').startOf('months').format(DATE_FORMAT));
    } else {
      setLeftValue(moment(defaultDates[0]).format(DISPLAY_DATE_CALENDAR_FORMAT));
    }
  };

  const handleLeftKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLeftBlur();
      event.preventDefault();
    }
  }

  const handleRightInput = (event) => {
    setRightValue(event.target.value);
  };

  const handleRightBlur = () => {
    const value = moment(rightValue, DISPLAY_DATE_CALENDAR_FORMAT);

    if (value.isValid()) {
      setDefaultDates([defaultDates[0], value.format(DATE_FORMAT)]);
    } else {
      setRightValue(moment(defaultDates[1]).format(DISPLAY_DATE_CALENDAR_FORMAT));
    }
  };

  const onLeftDateClick = (value) => {
    if (moment(value).isBefore(moment(defaultDates[0]))) {
      setDefaultDates([value, defaultDates[1]]);
      return;
    }

    if (moment(value).isAfter(moment(defaultDates[1]))) {
      setDefaultDates([defaultDates[0], value]);
      return;
    }

    if (moment(value).isAfter(moment(defaultDates[0])) && moment(value).isBefore(moment(defaultDates[1]))) {

      if (moment(value).diff(moment(defaultDates[1]), 'days') === 1) {
        setDefaultDates([value, defaultDates[1]]);
      } else {
        setDefaultDates([defaultDates[0], value]);
      }

      return;
    }

    if (moment(value).isSame(defaultDates[0])) {
      setDefaultDates([value, value]);
      return;
    }

    if (moment(value).isSame(defaultDates[1])) {
      setDefaultDates([value, value]);
      return;
    }
  };

  const handleRightKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleRightBlur();
      event.preventDefault();
    }
  };

  useEffect(() => {
    setDefaultDates(value);
  }, [value])

  useEffect(() => {
    setLeftValue(moment(defaultDates[0]).format(DISPLAY_DATE_CALENDAR_FORMAT));
    setRightValue(moment(defaultDates[1]).format(DISPLAY_DATE_CALENDAR_FORMAT));
  }, [defaultDates]);

  const onRightDateClick = (value) => {
    if (moment(value).isBefore(moment(defaultDates[0]))) {
      setDefaultDates([value, defaultDates[1]]);
      return;
    }

    if (moment(value).isAfter(moment(defaultDates[1]))) {
      setDefaultDates([defaultDates[0], value]);
      return;
    }

    if (moment(value).isAfter(moment(defaultDates[0])) && moment(value).isBefore(moment(defaultDates[1]))) {

      if (moment(moment(defaultDates[1])).diff(value, 'days') === 1) {
        setDefaultDates([defaultDates[0], value]);
      } else {
        setDefaultDates([value, defaultDates[1]]);
      }

      return;
    }

    if (moment(value).isSame(defaultDates[0])) {
      setDefaultDates([value, value]);
      return;
    }

    if (moment(value).isSame(defaultDates[1])) {
      setDefaultDates([value, value]);
      return;
    }
  };

  const apply = () => {
    onChange(defaultDates);
    onClose();
  };

  const cancel = () => {
    setDefaultDates(value);
    onClose();
    setFromMonthDate(moment(value[0]).startOf('months').format(DATE_FORMAT));
  }

  const actions = [
    {
      text: t('TODAY'),
      action: () => {
        const today = moment();
        setDefaultDates([today.format(DATE_FORMAT), today.format(DATE_FORMAT)]);
        setFromMonthDate(today.startOf('months').format(DATE_FORMAT));
      }
    },
    {
      text: t('YESTERDAY'),
      action: () => {
        const yesterday = moment().subtract(1, 'days');
        setDefaultDates([yesterday.format(DATE_FORMAT), yesterday.format(DATE_FORMAT)]);
        setFromMonthDate(yesterday.startOf('months').format(DATE_FORMAT));
      }
    },
    {
      text: t('LAST_7_DAYS'),
      action: () => {
        const date = moment();
        setDefaultDates([date.clone().subtract(7, 'days').format(DATE_FORMAT), date.format(DATE_FORMAT)]);

        setFromMonthDate(date.clone().subtract(7, 'days').startOf('months').format(DATE_FORMAT));
      }
    },
    {
      text: t('LAST_30_DAYS'),
      action: () => {
        const today = moment();
        const fromDate = today.clone().subtract(30, 'days');

        setDefaultDates([fromDate.format(DATE_FORMAT), today.format(DATE_FORMAT)]);

        setFromMonthDate(fromDate.startOf('months').format(DATE_FORMAT));
      }
    },
    {
      text: t('THIS_MONTH'),
      action: () => {
        const date = moment();
        const today = moment();
        setDefaultDates([date.startOf('months').format(DATE_FORMAT), today.format(DATE_FORMAT)]);

        setFromMonthDate(date.startOf('months').format(DATE_FORMAT));
      }
    },
    {
      text: t('LAST_MONTH'),
      action: () => {
        const date = moment().subtract(1, 'months');

        setFromMonthDate(date.startOf('months').format(DATE_FORMAT));

        setDefaultDates([date.startOf('months').format(DATE_FORMAT), date.endOf('months').format(DATE_FORMAT)]);
      }
    },
  ]

  return (
    <div className='date-filter bg-white h-fit w-max overflow-hidden ds-input-text'>
      <div className="date-wrapper flex flex-row">
        <div className="left">
          <div className='py-5'>
            {actions.map((item, index) => (
              <div className='px-3 py-1 cursor-pointer hover:text-gray-900 mt-1' onClick={item.action}
                   key={index}>{item.text}</div>
            ))}
          </div>
        </div>
        <div className='py-5 ps-3'>
          <div className="flex flex-row">
            <input
              className='ds-input'
              value={leftValue}
              onChange={handleLeftInput}
              onBlur={handleLeftBlur}
              onKeyDown={handleLeftKeyDown}
            />

            <input
              className='ds-input ml-4'
              value={rightValue}
              onChange={handleRightInput}
              onBlur={handleRightBlur}
              onKeyDown={handleRightKeyDown}
            />
          </div>

          <div className='flex flex-row mt-4'>
            <Calendar showPrev onMonthChange={onLeftMonthChange} onDateClick={onLeftDateClick} value={fromMonthDate}
                      range={defaultDates}/>

            <Calendar showNext onMonthChange={onRightMonthChange} onDateClick={onRightDateClick} value={toMonthDate}
                      range={defaultDates}/>
          </div>
        </div>
      </div>

      <div className='flex flex-row-reverse m-3'>
        <button
          type="button"
          onClick={apply}
          className="ds-button"
        >
          {t('APPLY')}
        </button>

        <button
          type="button"
          onClick={cancel}
          className="ds-text-color-violet me-2 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 focus:outline-none"
        >
          {t('CANCEL')}
        </button>
      </div>
    </div>
  );
});
