import {useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan} from '@fortawesome/free-solid-svg-icons';
import {CHART_CONFIG} from "consts";
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import UserPropertyInput from './UserPropertyInput';
import MultiSelectInput from './MultiSelectInput';
import GeographyInput from './GeographyInput';
import FilterInput from './FilterInput';
import AppFieldInput from './AppFieldInput';

import userPropertyOperatorTypes from './UserPropertyInput/types';
import geographyTypes from './GeographyInput/types';
import filterTypes from './FilterInput/types';
import appFieldOperatorTypes from './AppFieldInput/types';

import {propertyInputType} from 'consts/chartConfig';

const exampleOptions = ['Male', 'Female', 'Others', 'Unknown'];

export default function PropertyInput({
                                        value = {
                                          type: propertyInputType.USER_PROPERTIES,
                                        },
                                        onChange = () => {
                                        },
                                        onDelete = () => {
                                        },
                                        className,
                                      }) {
  const handleSelectTypeChange = (fieldValue) => {
    const newValue = {};

    newValue.type = fieldValue;

    if (newValue.type === propertyInputType.USER_PROPERTIES) {
      newValue.name = 'email';
      newValue.operator = userPropertyOperatorTypes.EQUALS;
    }

    if (newValue.type === propertyInputType.DEMOGRAPHICS) {
      newValue.name = 'Gender';
      newValue.value = [];
    }

    if (newValue.type === propertyInputType.GEOGRAPHY) {
      newValue.value = {country: "", state: "", city: ""};
      newValue.operator = geographyTypes.CONTAINS;
    }

    if (newValue.type === propertyInputType.TECHNOGRAPHICS) {
      newValue.name = 'Browser';
      newValue.value = [];
    }

    if (newValue.type === propertyInputType.SUBSCRIPTION_GROUP) {
      newValue.operator = filterTypes.INCLUDE;
      newValue.value = [];
    }

    if (newValue.type === propertyInputType.REACHABILITY) {
      newValue.name = 'Has device token';
      newValue.value = [];
    }

    if (newValue.type === propertyInputType.APP_FIELDS) {
      newValue.name = 'App Version';
      newValue.operator = appFieldOperatorTypes.CONTAINS;
      newValue.value = [];
    }

    if (newValue.type === propertyInputType.SEGMENTS) {
      newValue.operator = filterTypes.INCLUDE;
      newValue.value = [];
    }

    onChange(newValue);
  };

  useEffect(() => {
    const newValue = {...value};

    newValue.name = 'email';
    newValue.operator = userPropertyOperatorTypes.CONTAINS;

    onChange(newValue);
  }, []);

  return (
    <div className={`flex justify-start items-center gap-4 ${className}`}>
      <div>
        <TailwindComboboxWithoutForm items={CHART_CONFIG.COMMON_PROPERTY_OPTIONS} onChange={handleSelectTypeChange}/>
      </div>
      <div>
        {value.type === propertyInputType.USER_PROPERTIES && <UserPropertyInput value={value} onChange={onChange}/>}
        {value.type === propertyInputType.DEMOGRAPHICS &&
          <MultiSelectInput value={value} nameOptions={['Gender']} valueOptions={exampleOptions} onChange={onChange}/>}
        {value.type === propertyInputType.GEOGRAPHY && <GeographyInput value={value} onChange={onChange}/>}
        {value.type === propertyInputType.TECHNOGRAPHICS &&
          <MultiSelectInput value={value} nameOptions={['Browser', 'Device', 'OS']} valueOptions={exampleOptions}
                            onChange={onChange}/>}
        {value.type === propertyInputType.SUBSCRIPTION_GROUP &&
          <FilterInput value={value} valueOptions={exampleOptions} onChange={onChange}/>}
        {value.type === propertyInputType.REACHABILITY &&
          <MultiSelectInput value={value} nameOptions={['Has device token', 'Has email address', 'Has phone number']}
                            valueOptions={exampleOptions} onChange={onChange}/>}
        {value.type === propertyInputType.APP_FIELDS &&
          <AppFieldInput value={value} nameOptions={['App Version', 'Make', 'Model', 'OS Version', 'SDK Version']}
                         valueOptions={['1.2.3', '1.2.2', '1.2.1']} onChange={onChange}/>}
        {value.type === propertyInputType.SEGMENTS &&
          <FilterInput value={value} valueOptions={exampleOptions} onChange={onChange}/>}
      </div>
      <FontAwesomeIcon onClick={onDelete} className='text-red-500 cursor-pointer ms-2' icon={faTrashCan}/>
    </div>
  );
};
