import {useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashCan, faXmark} from '@fortawesome/free-solid-svg-icons';
import {DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT} from 'consts';
import {useParams} from "react-router-dom";
import {isArray, isUndefinedOrEmpty} from 'utils';
import {EQUALS} from "consts/chartConfig";

import DateFilter from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';
import FilterInput from 'components/SegmentFilterInput/input';
import FILTER_TYPES from 'components/SegmentFilterInput/types';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";
import {showError} from "components/FlashMessage/flashMessageSlice";

import MetaDataService from 'services/metadata';

import './index.scss';

export default function Form({
                               value = {
                                 paramEventName: '',
                                 paramDate: [
                                   moment().subtract(2, 'days').format(DATE_FORMAT),
                                   moment().add(2, 'days').format(DATE_FORMAT)
                                 ],
                                 paramFilter: [],
                               },
                               onChange = () => {
                               },
                               onDelete = () => {
                               },
                               className,
                             }) {
  const {t} = useTranslation();
  const wrapper = useRef(null);
  const dateWrapper = useRef(null);
  const dispatch = useDispatch();
  const {appId} = useParams();

  const [paramEventNameOptions, setParamEventNameOptions] = useState([]);
  const [eventPropertyOptions, setEventPropertyOptions] = useState([]);

  const getParamEventNameOptions = async () => {
    try {
      const result = await MetaDataService.getEventNameList({appId});

      setParamEventNameOptions(result.map((value) => ({text: value, value})));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const getParamEventPropertyOptions = async (fieldKey) => {
    try {
      if (!fieldKey) {
        return;
      }
      const result = await MetaDataService.getFieldValues({appId, fieldKey});

      setEventPropertyOptions(result.map((value) => ({text: value, value})));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const handleSelectEventNameChange = (fieldValue) => {
    onChange({
      ...value,
      paramEventName: fieldValue,
    });
  };

  const onDateChange = (newValue) => {
    onChange({
      ...value,
      paramDate: newValue,
    });
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const addFilter = () => {
    const newValue = {...value};

    newValue.paramFilter.push({
      type: FILTER_TYPES.EVENT_PROPERTY,
      value: {
        property: eventPropertyOptions[0] ? eventPropertyOptions[0].value : '',
        operator: EQUALS,
        value: "",
      },
    });

    onChange(newValue);
  };

  const deleteFilter = (index) => {
    const newValue = {...value};

    newValue.paramFilter.splice(index, 1);

    onChange(newValue);
  };

  const handleFilterChange = (data, index) => {
    const newValue = {...value};

    newValue.paramFilter[index] = data;

    onChange(newValue);
  };

  useEffect(() => {
    getParamEventNameOptions();
  }, [appId]);

  useEffect(() => {
    getParamEventPropertyOptions(value.paramEventName);
  }, [value.paramEventName]);

  useEffect(() => {
    if (isArray(paramEventNameOptions) && paramEventNameOptions.length > 0 && isUndefinedOrEmpty(value.paramEventName)) {
      onChange({
        ...value,
        paramEventName: paramEventNameOptions[0].value,
      });
    }
  }, [paramEventNameOptions]);

  return (
    <div className={`relative grid grid-cols-1 gap-2 mb-3 ds-block ${className}`} >
      <FontAwesomeIcon onClick={onDelete} className='absolute text-red-500 right-2 top-6 cursor-pointer'
                       icon={faTrashCan}/>
      <div className='flex items-center justify-start gap-4 mr-5'>
        <div>
          <TailwindComboboxWithoutForm items={paramEventNameOptions} onChange={handleSelectEventNameChange} value={value.paramEventName} />
        </div>
        <div>
          <ToggleBox ref={wrapper} onClose={onClose}>
            <ToggleBox.Button>
              <div className="ds-input">
                <span>{moment(value.paramDate[0]).format(DISPLAY_DATE_CALENDAR_FORMAT)} - {moment(value.paramDate[1]).format(DISPLAY_DATE_CALENDAR_FORMAT)}</span>
              </div>
            </ToggleBox.Button>

            <ToggleBox.Content>
              <DateFilter ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter} value={value.paramDate}/>
            </ToggleBox.Content>
          </ToggleBox>
        </div>
      </div>

      <div className='mt-2'>
        <span onClick={addFilter} className='text-blue-700 hover:text-blue-500 cursor-pointer select-none ds-text-color'>
          + {t("FILTER_BY")}
        </span>
      </div>

      {value.paramFilter.map((item, index) => (
        <FilterInput
          key={index}
          value={item}
          onChange={(value) => handleFilterChange(value, index)}
          eventPropertyOptions={eventPropertyOptions}
          onDelete={() => deleteFilter(index)}
          className="mt-2"
        />
      ))}
    </div>
  );
}
