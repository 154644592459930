

export default function LPTextBlock({title, description, classNames, type, link, description_items=[]}) {
  return (
    <div className={"px-2"}>
      {
        type === "bg_dark" ?
          <div className={`${classNames}`}>
            <div className={"text-violet-500 font-semibold text-3xl lg:text-5xl"}>{title}</div>
            <div className={"lp-text-desc-bg-dark mt-3"}>{description}</div>
            {link && <div className={"lg:mt-8 mt-2 lp-learn-more"}><a href={link}>Learn more</a></div>}

          </div>
          :
          <div className={`${classNames}`}>
            <div className={"text-violet-500 font-semibold text-3xl lg:text-5xl"}>{title}</div>
            <div className={"lp-text-desc-bg-light mt-3"}>{description}</div>
            {link && <div className={"mt-12 lp-learn-more hover:bg-violet-400 hover:scale-105 focus:outline-none focus:ring-4 focus:ring-violet-300 transition-all duration-500 ease-in-out"}><a href={link}>Learn more</a></div>}
              <ul className="list-none mt-5">
                {
                  description_items && description_items.map((item) => (
                    <li className={"lp-text-desc-bg-light"}>{item}</li>
                  ))
                }
              < /ul>
          </div>
      }
    </div>
  )
}
