import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import 'views/analytics/events/index.scss';
import {FormProvider, useForm} from "react-hook-form";
import {buildGlobalFilterRequest} from "views/visualisation/chartProcessor";
import {isUndefinedOrEmpty} from "utils";
import {CHART_CONFIG, PATHS} from "consts";
import {frequencyOptions, limitOptions, orderOptions, showDataOptions} from "consts/chartConfig";
import {showError} from "components/FlashMessage/flashMessageSlice";
import {useDispatch, useSelector} from "react-redux";
import DateFilterToggleBox from "components/DateFilterToggleBox";
import ChartSerivce from "services/chart";
import FieldFilterToogleBox from "components/FieldFilterToogleBox";
import Visualisation from "views/overview/visualisation";
import {buildAppList} from "utils/app";
import DsListBox from "components/DsListBox";
import {useParams} from "react-router-dom";
import FieldFilterModal from "views/analytic/FieldFilterModal";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldFilterToggleBox2 from "components/FieldFilterToggleBox2";

export default function AnalyticDashboard() {
  const {t} = useTranslation();
  const methods = useForm();
  const subform = useForm()
  const dispatch = useDispatch();
  const [globalFiterRequest, setGlobalFiterRequest] = useState([]);
  const [fieldList, setFieldList] = useState([]);
  const [openAddFieldFilter, setOpenAddFieldFilter] = useState(false);
  const fieldFilter = methods.watch(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_FILTER}`);
  const {appId} = useParams()
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    getChartFieldList()
  }, [appId]);


  const getChartFieldList = async () => {
    try {
      if (!isUndefinedOrEmpty(appId)) {
        const result = await ChartSerivce.getFieldList({appId: appId});
        setFieldList(result);
      }
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  const chartDimensions = useMemo(() => {
    const value = fieldList.filter(element => (element.attribute === 'dimension' && element.isSelectedOption));
    return value ? value.map((item) => ({name: item.name, fieldKey: item.fieldKey, value: item.fieldKey})) : [];
  }, [fieldList])

  const chartMetrics = useMemo(() => {
    const value = fieldList.filter(
      (element) => element.attribute === "metric" && element.isSelectedOption
    );
    return value
      ? value.map((item) => ({name: item.name, fieldKey: item.fieldKey, value: item.fieldKey}))
      : [];
  }, [fieldList]);

  useEffect(() => {
    methods.handleSubmit(onSubmit)()
  }, [chartDimensions]);

  const onSubmit = async (data) => {
    try {
      const globalFiterRequest = buildGlobalFilterRequest(data);
      setGlobalFiterRequest(globalFiterRequest);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  const onAddFilter = (data) => {
    if (isUndefinedOrEmpty(fieldFilter)) {
      methods.setValue(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_FILTER}`, [data]);
    } else {
      const newFieldFilter = fieldFilter;
      newFieldFilter.push(data);
      methods.setValue(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_FILTER}`, newFieldFilter);
    }
  }

  const onDelFilter = (index) => {
    const newFieldFilter = fieldFilter;
    newFieldFilter.splice(index, 1);
    methods.setValue(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_FILTER}`, newFieldFilter);
  }

  const onReload = () => {
    methods.handleSubmit(onSubmit)();
  }

  useEffect(() => {
    const subscription = methods.watch(() => {
      onReload()
    })
    return () => subscription.unsubscribe()
  }, [JSON.stringify(methods.watch())])

  const subFilter = useMemo(() => {
    return fieldList.filter(
      (element) =>
        element.attribute === "dimension" &&
        element.filterable === true &&
        element.fieldKey !== CHART_CONFIG.CHART_DATE_FIELD
      // && getValues(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_SUB_FILTER}`).includes(element.value)
    );
  }, [fieldList]);

  const [globalFilter, setGlobalfilter] = useState([]);
  useEffect(() => {
    const filteredFields = fieldList.filter(element => {
      const isFieldInFilter = filter.includes(element.fieldKey);
      if (!isFieldInFilter && element.fieldKey !== CHART_CONFIG.CHART_DATE_FIELD) {
        methods.setValue(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${element.fieldKey}`, []);
      }
      return isFieldInFilter;
    });

    setGlobalfilter(filteredFields);
  }, [filter]);


  useEffect(() => {
    setFilter(subform.getValues(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_SUB_FILTER}`))
  }, [subform.watch(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_SUB_FILTER}`)])


  const appList = useSelector((state) => state.app.appList);
  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.ANALYTIC_DASHBOARD);
  }, [appList]);


  return (
    <div id="events-details">
      <div className="ds-text-page-title">{t("ROI")}</div>
      <DsListBox listData={appOptions}/>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={`flex flex-row w-full mt-5 space-x-2`}>
            <div className="">
              <DateFilterToggleBox
                name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_DATE_FIELD}`}
              />
            </div>
            <FormProvider {...subform}>
              <FieldFilterToggleBox2
                options={subFilter}
                name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_SUB_FILTER}`}
                defaultValue={!isUndefinedOrEmpty(globalFilter) ? globalFilter[0].value : []}
                className="ds-input ds-input-text ds-gray-background ds-violet-border"
                displayedName={t('Filter By: ')}
              />
            </FormProvider>

            {globalFilter.map((filter, index) => (
              <div key={index} className="">
                <FieldFilterToogleBox
                  appId={appId}
                  fieldKey={filter.fieldKey}
                  displayedName={t(filter.fieldKey)}
                  name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${filter.fieldKey}`}
                />
              </div>
            ))}


            {fieldFilter && fieldFilter.map((filter, index) => (
              <div key={index}
                   className="items-center flex flex-row ds-input ds-input-text ds-gray-background ds-violet-border"
              >
                {filter.name} <span className='ms-1'>{filter.operator} {filter.value}</span>
                <FontAwesomeIcon
                  icon={faTrashCan}
                  onClick={(index) => onDelFilter(index)}
                  className='ms-2 h-3 w-3 font-extrabold text-slate-500 hover:text-red-500'/>
              </div>
            ))}

            {/*<button*/}
            {/*  type="button"*/}
            {/*  className="ds-button"*/}
            {/*  onClick={() => setOpenAddFieldFilter(true)}*/}
            {/*>*/}
            {/*  Add Filter*/}
            {/*</button>*/}
          </div>

          <div className="w-full mt-5">
            <div className="grid grid-cols-2 gap-4">

              <div className="ds-block col-span-2 ds-back">
                {chartDimensions.length > 0 && (
                  <Visualisation
                    chartIndex={0}
                    appId={appId}
                    defaultChartType={CHART_CONFIG.CHART_TYPE_TABLE}
                    globalFilter={globalFiterRequest}
                    chartId={CHART_CONFIG.CHART_1}
                    multiDimensions={chartDimensions}
                    multiMetrics={chartMetrics}
                    limitOptions={limitOptions}
                    orderOptions={orderOptions}
                    frequencyOptions={frequencyOptions}
                    // dataOptions={showDataOptions}
                    title={true}
                  />
                )}
              </div>
            </div>
          </div>
        </form>
      </FormProvider>

      {/*<FieldFilterModal*/}
      {/*  isOpen={openAddFieldFilter}*/}
      {/*  onChange={onAddFilter}*/}
      {/*  fieldList={CHART_CONFIG.FIELD_LIST}*/}
      {/*  operatorList={CHART_CONFIG.OPERATORS_LIST}*/}
      {/*  closeModal={() => setOpenAddFieldFilter(false)}*/}
      {/*/>*/}
    </div>
  );
};
