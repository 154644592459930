import {useState, useEffect, useRef, Children, forwardRef, useImperativeHandle} from 'react';
import $ from 'jquery';

/**
 * Toggle box component for toggling content visibility.
 * @param {Object} props - The props object.
 * @param {boolean} [props.firstOpen=false] - Indicates if the box should be open by default.
 * @param {boolean} [props.autoClose=false] - When click will close.
 * @param {string} [props.position='left'] - The position of the toggle box (left, right, top, bottom).
 * @param {JSX.Element} props.children - The children elements, including Button and Content.
 * @param {function} [props.onClose=()=>{}] - The function to call when the box is closed.
 * @param {string} [props.className=''] - Additional classes for styling.
 * @param {React.Ref} ref - Forwarded ref for imperatively controlling the component.
 * @returns {JSX.Element} A React component representing the ToggleBox.
 */
const ToggleBox = forwardRef(({firstOpen = false, autoClose = false, position = 'left', children, onClose = () => {}, className}, ref) => {
  let _button, _content;

  Children.forEach(children, child => {
    if (child.type === Button) {
      return _button = child
    }

    if (child.type === Content) {
      return _content = child
    }
  });

  const [active, setActive] = useState(firstOpen);
  const wrapper = useRef(null);

  const watchWrapper = (e) => {
    if (wrapper.current && !wrapper.current.contains(e.target)) {
      setActive(false);
    }
  };

  const open = () => {
    setTimeout(() => setActive(true));
  };

  const close = () => {
    setTimeout(() => setActive(false));
  };

  const toggle = () => {
    setTimeout(() => setActive(!active));
  };

  useImperativeHandle(ref, () => ({
    open,
    close,
    toggle,
  }));

  const handleClick = () => {
    if (autoClose) {
      setTimeout(() => setActive(!active));
    }
  }

  useEffect(() => {
    if (active) {
      $(wrapper.current).show();
    } else {
      onClose();
      setTimeout(() => $(wrapper.current).hide(), 400);
    }
  }, [active]);

  useEffect(() => {
    setTimeout(() => {
      $(document.body).on('click', watchWrapper);
      if (!active) {
        $(wrapper.current).hide();
      }
    }, 500);

    return () => {
      $(document.body).off('click', watchWrapper);
    }
  }, []);

  return (
    <div className={`ds-toggle-box ${className}`}>
      <div onClick={toggle}>
        {_button}
      </div>

      <div
        ref={wrapper}
        onClick={handleClick}
        className={`toggle-box-wrapper transition-opacity duration-300 ease-in-out shadow overflow-hidden ${position} ${active ? 'opacity-100' : 'opacity-0'}`}
      >
        {_content}
      </div>
    </div>
  );
})

const Button = ({children}) => <div>{children}</div>
const Content = ({children}) => <div>{children}</div>

ToggleBox.Button = Button
ToggleBox.Content = Content

export default ToggleBox;
