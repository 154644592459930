import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {debounce} from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleCheck, faClone, faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons';
import {Link, useParams} from "react-router-dom";
import {DISPLAY_DATE_TIME_FORMAT, PATHS} from "consts";
import moment from "moment/moment";
import {buildAppList} from "utils/app";

import DsListBox from "components/DsListBox";
import TextInput from "components/TextInput/WithoutForm";
import {showError} from "components/FlashMessage/flashMessageSlice";
import TailwindMenuDialog from "components/TailwindMenuDialog";
import {DsIconDelete} from "components/DsIcon";

import SegmentService from 'services/segment';

import DeleteSegmentModal from "./DeletePopup";

import './index.scss';


export default function Segments() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {appId} = useParams();
  const [segmentList, setSegmentList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [segmentId, setSegmentId] = useState('');
  const [segmentName, setSegmentName] = useState('');
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.SEGMENTS);
  }, [appList]);

  const handleDelete = (segment) => {
    setSegmentId(segment.id);
    setSegmentName(segment.name);
    setIsDeleteOpen(true);
  }

  const selectItem = (index) => {
    const newData = [...segmentList];

    newData[index].selected = !newData[index].selected;

    setSegmentList(newData);
  };

  const showDeleted = useMemo(() => {
    return segmentList.some(obj => obj.selected === true);
  }, [segmentList]);

  const allSelected = useMemo(() => {
    return segmentList.every(obj => obj.selected === true);
  }, [segmentList]);

  const deletedId = useMemo(() => {
    return segmentList.filter(obj => obj.selected === true).map((item) => ({id: item.id}));
  }, [segmentList]);

  const selectAll = (event) => {
    const newData = segmentList.map((item) => ({...item, selected: event.target.checked}));

    setSegmentList(newData);
  };

  const methods = useForm();

  const onSubmit = async (data) => {
    console.log(data);
  };

  const debouncedOnSubmit = debounce(onSubmit, 500);

  useEffect(() => {
    const subscription = methods.watch(methods.handleSubmit(debouncedOnSubmit));
    return () => subscription.unsubscribe();
  }, [methods.handleSubmit, methods.watch]);

  const fetchData = async () => {
    try {
      const response = await SegmentService.listSegments({appId});
      for (let i = 0; i < response.length; i++) {
        try {
          response[i].result = JSON.parse(response[i].result);
        } catch (e) {
          response[i].result = {};
        }
      }

      setSegmentList(response);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  }

  useEffect(() => {
    fetchData().then(r => r);
  }, [appId]);

  const TrChild = ({index}) => {
    const [show, setShow] = useState(false);
    const [copied, setCopied] = useState(0);

    const handleMouseEnter = () => setShow(true);

    const handleMouseLeave = () => setShow(false);

    const copyName = (e) => {
      e.stopPropagation();

      navigator.clipboard.writeText(segmentList[index].name);
      setCopied(1);

      setTimeout(() => setCopied(0), 1000);
    };

    const copyId = () => {
      navigator.clipboard.writeText(segmentList[index].id);
      setCopied(2);

      setTimeout(() => setCopied(0), 1000);
    };

    return (
      <tr>
        <td
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className='px-3 py-2'
        >
          <div className='flex justify-start items-start'>
            <div className='ms-2'>
              <Link className='text-blue-700'
                    to={`${PATHS.PROJECT}/${appId}${PATHS.SEGMENT_DETAIL}?id=${segmentList[index].id}`}>
                {segmentList[index].name}
              </Link>
              <span>{show && <FontAwesomeIcon onClick={copyName} icon={copied === 1 ? faCircleCheck : faClone}
                                              className='cursor-pointer text-blue-700 ms-2'/>}</span>

              <div className='flex justify-start items-start mt-1'>
                {show && <FontAwesomeIcon onClick={copyId} icon={copied === 2 ? faCircleCheck : faClone}
                                          className='cursor-pointer text-blue-700 ms-2'/>}
              </div>

              <div className='text-sm mt-1 text-gray-600'>Created by: {segmentList[index].createdBy}</div>
            </div>
          </div>
        </td>
        <td className='px-3 py-6'>{segmentList[index].createdAt}</td>
        <td className='px-3 py-6'>{segmentList[index].updatedAt}</td>
        <td className='px-3 py-6'>{segmentList[index].updatedBy}</td>
        <td className='px-3 py-6'>{segmentList[index].result.totalUsers}</td>
      </tr>
    );
  };

  return (
    <div id="segment-list">
      <div className="font-bold text-xl me-2">{t('SEGMENT')}</div>

      <DsListBox listData={appOptions}/>

      <div className="ds-block mt-3">
        {/*<div className="flex">*/}
        {/*  <div className="flex flex-row">*/}
        {/*    <TextInput*/}
        {/*      name="search"*/}
        {/*      icon={faMagnifyingGlass}*/}
        {/*      placeholder={t('Search segments')}*/}
        {/*      onChange={(e) => setSearchTerm(e.target.value)}*/}
        {/*      className={"pl-8"}*/}
        {/*    />*/}

        {/*  </div>*/}
        {/*</div>*/}

        <div className="relative ds-input-text">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
            <tr>
              <th scope="col" className="py-3 pl-4 pr-3 text-center sm:pl-0">
                {t('NAME')}
              </th>
              <th scope="col" className="px-3 py-3.5 text-center">
                {t('TOTAL_USERS')}
              </th>
              <th scope="col" className="px-3 py-3.5 text-left">
                {t('CREATED_BY')}
              </th>
              <th scope="col" className="px-3 py-3.5 text-left">
                {t('CREATED_AT')}
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
              </th>
            </tr>
            </thead>
            <tbody>
            {segmentList && segmentList.map((segment, index) => (
              <tr key={index} className="bg-white border-b">
                <td className="whitespace-nowrap py-4 pl-4 pr-3 sm:pl-0 text-center">
                  <Link
                    className="text-sm no-underline ds-text-color"
                    to={`${PATHS.APP}/${appId}${PATHS.SEGMENT_DETAIL}/${segment.id}`}
                  >
                    {segment.name}
                  </Link>
                </td>
                <td className="whitespace-nowrap px-3 py-4 text-center">{segment.result.totalUsers}</td>
                <td className="whitespace-nowrap px-3 py-4">{segment.createdBy}</td>
                <td
                  className="whitespace-nowrap px-3 py-4">{moment(segment.createdAt).format(DISPLAY_DATE_TIME_FORMAT)} UTC
                </td>
                <td className="relative whitespace-nowrap">
                  <TailwindMenuDialog
                    items={[
                      {
                        Name: "Delete",
                        Icon: <DsIconDelete/>,
                        Action: () => handleDelete(segment),
                        ActionType: "button"
                      },
                    ]}
                  />
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>

        {/*<div className="flex justify-between mt-3 px-2">*/}
        {/*  <div></div>*/}
        {/*  <div></div>*/}
        {/*  <div className='flex flex-row items-center'>*/}
        {/*    <span className='me-2 text-sm'>{t('SHOW')}</span>*/}
        {/*    <SelectInput name="pageSize" options={entries}/>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

      <DeleteSegmentModal
        appId={appId}
        id={segmentId}
        name={segmentName}
        isOpen={isDeleteOpen}
        closeModal={() => setIsDeleteOpen(false)}
        reset={fetchData}
      />
    </div>
  );
}
