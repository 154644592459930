export default function DsIconAddToDashboard() {
  return (
    <svg viewBox="0 0 48.00 48.00" xmlns="http://www.w3.org/2000/svg" fill="#8b5cf6" stroke="#8b5cf6"
         stroke-width="0.00048000000000000007" transform="matrix(-1, 0, 0, 1, 0, 0)rotate(0)">
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC"
         stroke-width="0.576"></g>
      <g id="SVGRepo_iconCarrier">
        <g id="Layer_2" data-name="Layer 2">
          <g id="invisible_box" data-name="invisible box">
            <rect width="48" height="48" fill="none"></rect>
          </g>
          <g id="icons_Q2" data-name="icons Q2">
            <g opacity="0.99">
              <path d="M20,30H8a2,2,0,0,0-2,2V42a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V32a2,2,0,0,0-2-2Z"></path>
              <path
                d="M18,4H8A2,2,0,0,0,6,6V24a2,2,0,0,0,2,2H18a1.1,1.1,0,0,0,.9-1.6A16.9,16.9,0,0,1,16,15a16.6,16.6,0,0,1,2.9-9.4A1.1,1.1,0,0,0,18,4Z"></path>
              <path
                d="M33,32a15.6,15.6,0,0,1-5.7-1A1,1,0,0,0,26,32V42a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V31.1a1,1,0,0,0-1.4-.9A16.4,16.4,0,0,1,33,32Z"></path>
              <path
                d="M33,2A13,13,0,1,0,46,15,13,13,0,0,0,33,2Zm5.4,14.4A2,2,0,0,1,37,17H35v2a2,2,0,0,1-2,2,2.1,2.1,0,0,1-2-2V17H29a2,2,0,0,1-2-2,2.1,2.1,0,0,1,2-2h2V11a2,2,0,0,1,2-2,2.1,2.1,0,0,1,2,2v2h2a2,2,0,0,1,2,2A2,2,0,0,1,38.4,16.4Z">
              </path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
