import { useMemo } from 'react';
import MultiSelectOption from 'components/MultiSelectOption/WithoutForm';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import types from './types';

const operatorOptions = [
  {
    text: 'Include',
    value: types.INCLUDE,
  },
  {
    text: 'Exclude',
    value: types.EXCLUDE,
  },
];


export default function FilterInput({
  value = {
    operator: types.INCLUDE,
    value: [],
  },
  valueOptions = [],
  onChange = () => { },
}) {
  const handleSelectOperatorChange = (fieldValue) => {
    const newValue = { ...value };

    newValue.operator = fieldValue;

    onChange(newValue);
  };

  const handleSelectValueChange = (newValue) => {
    onChange({
      ...value,
      value: newValue,
    });
  };

  const options = useMemo(() => {
    return valueOptions.map(item => ({text: item, value: item}))
  }, [valueOptions]);

  return (
    <div className='flex justify-start items-center w-full'>

      <TailwindComboboxWithoutForm items={operatorOptions} onChange={handleSelectOperatorChange} />

      <MultiSelectOption value={value.value} options={options} onChange={handleSelectValueChange} />
    </div>
  );
}
