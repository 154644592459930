import {useTranslation} from 'react-i18next';
import {useController, useFormContext} from 'react-hook-form';

import Input from './input';

import './index.scss';
import {useEffect} from "react";

export default function UserWhoLike({
                                      name,
                                      className,
                                      defaultValue = {
                                        operator: "AND",
                                        value: [],
                                      }
                                    }) {
  const {t} = useTranslation();
  const {control} = useFormContext();
  const {field} = useController({name, control, defaultValue});

  const onChange = (value) => {
    field.onChange(value);
  };

  useEffect(() => {
    onChange(defaultValue);
  }, [JSON.stringify(defaultValue)]);

  return (
    <div className={`${className}`}>
      <div>
        <span className='ds-text-form-title'>{t('USERS_WHO_LIKE')}</span>
        <span className='ds-text-lighter ml-1'>({t('OPTIONAL')})</span>
      </div>
      <Input onChange={onChange} value={field.value}/>
    </div>
  );
}
