import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {useParams} from "react-router-dom";
import {FormProvider, useForm} from 'react-hook-form';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComment, faUserGroup,} from '@fortawesome/free-solid-svg-icons';
import {addCommas, isUndefinedOrEmpty} from 'utils';
import {buildAppList} from "utils/app";
import {PATHS} from "consts";

import DsListBox from "components/DsListBox";
import LoadingSpinner from 'components/LoadingSpinner';
import {showError} from 'components/FlashMessage/flashMessageSlice';

import SegmentService from 'services/segment';

import UsersWhoLike from './UsersWhoLike';
import UsersWhoDid from './UsersWhoDid';
import DidNotDo from './DidNotDo';
import DisplayCommonProperties from './DisplayCommonProperties';

import {proceedParam} from "./processor";

import {SaveToSegment} from "../save_to_segment";

import './index.scss';

export default function FindPeople() {
  const {t} = useTranslation();
  const methods = useForm();
  const dispatch = useDispatch();

  const {appId, segmentId} = useParams();
  const [rawPayload, setRawPayload] = useState({});
  const [result, setResult] = useState([]);
  const [payload, setPayload] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const payload = proceedParam(data);
    if (payload.blocks.length > 0) {
      try {
        const requestResponse = await SegmentService.getSegmentData({appId, payload});
        setPayload(payload);
        setResult(requestResponse);
        setRawPayload(data);
      } catch (error) {
        const {message} = error;
        dispatch(showError({message}));
      }
    } else {
      dispatch(showError({message: 'Data is empty'}));
    }

    setIsLoading(false);
  };

  const loadSegmentPayload = async () => {
    if (segmentId) {
      try {
        const response = await SegmentService.getSegment({appId, segmentId});
        setRawPayload(JSON.parse(response.rawPayload));
      } catch (error) {
        const {message} = error;
        // dispatch(showError({message}));
    }
  }
  }

  const appList = useSelector((state) => state.app.appList);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.FIND_PEOPLE);
  }, [appList]);

  useEffect(() => {
    loadSegmentPayload();
  }, [segmentId]);

  return (
    <>
      <DsListBox listData={appOptions}/>

      <div id="find-people" className='ds-block ds-input-text mt-5'>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className='grid grid-rows-1 p-2 gap-2'>
              <div className="flex items-center">
                <FontAwesomeIcon className='icon mr-2' icon={faUserGroup}/>
                <span className='ds-text-form-title'>{t('SEGMENT')}</span>
              </div>
              <div className="flex items-center">
                <span className='ds-text-form-title'>{t('BY_INTEREST')}</span>
                <span className='ds-text-lighter'>( {t('EXAMPLE_USERS_WHO_LIKE')} )</span>
              </div>
              <div className="flex items-center ds-block">
                {rawPayload ?
                  <UsersWhoLike name="userLike" defaultValue={rawPayload.userLike}/>
                  : <UsersWhoLike name="userLike"/>
                }
              </div>
              <div className="flex items-center">
                <span className='ds-text-form-title'><span
                  className='uppercase'>{t('AND')}</span> {t('by behaviour')}</span>
                <span className='ds-text-lighter ml-2'>( {t('EXAMPLE_USERS_WHO_DID')} )</span>
              </div>

              <div className="flex items-center ds-block">
                {rawPayload ?
                  <UsersWhoDid name="userDid" defaultValue={rawPayload.userDid}/>
                  : <UsersWhoDid name="userDid"/>
                }
              </div>
              <div className="flex items-center">
                <span className='uppercase ds-text-form-title'>{t('AND')}</span>
              </div>

              <div className="flex items-center ds-block">
                {rawPayload ?
                  <DidNotDo name="didNotDo" defaultValue={rawPayload.didNotDo}/>
                  : <DidNotDo name="didNotDo"/>
                }
              </div>
              <div className="flex items-center">
                <span className='uppercase ds-text-form-title'>{t('AND')}</span>
              </div>
              <div className="flex items-center ds-block">
                {rawPayload ?
                  <DisplayCommonProperties name="displayCommonProperties" defaultValue={rawPayload.displayCommonProperties}/>
                  : <DisplayCommonProperties name="displayCommonProperties"/>
                }
              </div>
              <div className="flex items-center">
                <button
                  disabled={isLoading}
                  type="submit"
                  className="ds-button"
                >
                  {isLoading ?
                    <>
                      <LoadingSpinner className="mb-1.5 me-3"/>
                      {t('LOADING')}
                    </>
                    :
                    t('VIEW_DETAILS')
                  }
                </button>
              </div>
            </div>
          </form>
        </FormProvider>

        <hr className="border-t border-0 border-gray-200 my-4"/>

        {!isUndefinedOrEmpty(result) &&
          <div className="p-1.5">
            <div className='flex justify-start items-center'>
              <SaveToSegment
                queryScript={result.queryScript}
                payload={payload}
                result={result.result}
                rawPayload={rawPayload}
              />

              {/*<Tooltip minWidth='200'>*/}
              {/*  <Tooltip.Label>*/}
              {/*    <div className='flex justify-start items-center text-blue-600 hover:text-blue-400 ml-2'>*/}
              {/*      <FontAwesomeIcon icon={faBookmark} />*/}
              {/*      <span className='ds-text-lighter ml-2'>{t('SAVE_SEGMENT_AS_BOOKMARK')}</span>*/}
              {/*    </div>*/}
              {/*  </Tooltip.Label>*/}
              {/*  <Tooltip.Content>*/}
              {/*    <p>{t('SAVE_SEGMENT_AS_BOOKMARK_EXAMPLE')}</p>*/}
              {/*  </Tooltip.Content>*/}
              {/*</Tooltip>*/}
            </div>

            <div className='header flex justify-start items-center mt-5'>
              <FontAwesomeIcon className='icon px-2 py-3' icon={faComment}/>
              <span className='ds-text-form-title'>{t('Segment Details')}</span>
            </div>

            <div className='body px-3 py-5'>
              <div className='flex justify-center items-center'>
                <div className='text-center ds-input-text'>
                  <div>{t('TOTAL_USERS')}</div>
                  <div className='font-semibold text-2xl mt-1'>{addCommas(result.result.totalUsers)}</div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
}
