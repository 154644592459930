import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import { DATE_FORMAT } from 'consts';
import { whereValue } from 'consts/chartConfig';
import { showError } from "components/FlashMessage/flashMessageSlice";

import MetaDataService from 'services/metadata';

import Form from './Form';
import { typeValue, operatorValue } from './Form/types';
import { useParams } from "react-router-dom";
import {use} from "i18next";

export default function Input({
  value = {
    operator: "AND",
    value: []
  },
  onChange = () => { },
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [paramEventNameOptions, setParamEventNameOptions] = useState([]);
  const [eventPropertyOptions, setEventPropertyOptions] = useState([]);
  const { appId } = useParams();

  const addData = () => {
    const data = { ...value };

    data.value.push({
      type: typeValue.EVENT_PROPERTY,
      paramEventName: paramEventNameOptions.length > 0 ? paramEventNameOptions[0].value : '',
      paramDate: [
        moment().subtract(2, 'days').format(DATE_FORMAT),
        moment().add(2, 'days').format(DATE_FORMAT)
      ],
      paramFrequency: whereValue.PREDOMINANTLY,
      paramFrequencyAtLeastValue: 5,
      paramEventProperty: {
        name: '',
        operator: operatorValue.EQUALS,
        value: '',
      },
    });

    onChange(data);
  };

  const deleteData = (index) => {
    const data = { ...value };

    data.value.splice(index, 1);

    onChange(data);
  };

  const setDataForm = (index, newData) => {
    const data = { ...value };

    data.value[index] = newData;

    onChange(data);
  };

  const getEventNameProperties = async () => {
    try {
      const result = await MetaDataService.getEventNameList({ appId });

      setParamEventNameOptions(result.map((value) => ({ text: value, value })));
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  const handleEventNameChange = async (fieldKey) => {
    try {
      if (!fieldKey) {
        return;
      }
      const result = await MetaDataService.getFieldValues({ appId, fieldKey });

      setEventPropertyOptions(result.map((value) => ({ text: value, value })));
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  };

  useEffect(() => {
    getEventNameProperties();
  }, [appId]);

  return (
    <>
      {value.value.map((item, index) => (
        <Form
          key={index}
          value={item}
          onChange={(data) => setDataForm(index, data)}
          onDelete={() => deleteData(index)}
          paramEventNameOptions={paramEventNameOptions}
          onEventNameChange={handleEventNameChange}
          eventPropertyOptions={eventPropertyOptions}
          className="mt-1"
        />
      ))}
      <div onClick={addData} className="ds-button-2">
        + {t("INTEREST")}
      </div>
    </>
  )
}
