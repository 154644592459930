import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

import Input from './input';

import './index.scss';
import {useEffect, useMemo} from 'react';

export default function DidNotDo({
  name,
  className,
  defaultValue = {
    operator: "OR",
    value: [],
  }
}) {
  const { t } = useTranslation();
  const { control, watch } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const value = watch(name);

  const haveValues = useMemo(() => {
    return value?.value?.length > 0;
  }, [value]);

  const onChange = (value) => {
    field.onChange(value);
  };

  useEffect(() => {
    onChange(defaultValue);
  }, [JSON.stringify(defaultValue)]);

  return (
    <div className={`${className}`}>
      <div>
        <span className='ds-text-form-title mr-2'>{haveValues ? t('DID_NOT_DO_ANY_OF_THESE_EVENTS') : t('DID_NOT_DO')}</span>
        <span className='ds-text-lighter'>({t('OPTIONAL')})</span>
      </div>
      <Input onChange={onChange} value={field.value} />
    </div>
  );
}
