import { useTranslation } from "react-i18next";
import moment from 'moment';
import { DATE_FORMAT } from 'consts';

import Form from './Form';


export default function Input({
  value = {
    operator: "AND",
    value: []
  },
  onChange = () => { },
}) {
  const { t } = useTranslation();

  const addData = () => {
    const data = { ...value };

    data.value.push({
      paramEventName: '',
      paramDate: [
        moment().subtract(2, 'days').format(DATE_FORMAT),
        moment().add(2, 'days').format(DATE_FORMAT)
      ],
      paramFilter: [],
    });

    onChange(data);
  };

  const deleteData = (index) => {
    const data = { ...value };

    data.value.splice(index, 1);

    onChange(data);
  };

  const setDataForm = (index, newData) => {
    const data = { ...value };

    data.value[index] = newData;

    onChange(data);
  };

  return (
    <>
      {value.value.map((item, index) => (
        <Form
          key={index}
          value={item}
          onChange={(data) => setDataForm(index, data)}
          onDelete={() => deleteData(index)}
          className="mt-1"
        />
      ))}
      <div onClick={addData} className="ds-button-2">
        + {t("EVENT")}
      </div>
    </>
  )
};
