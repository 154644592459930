import { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';

import DateFilter from 'components/DateFilter/WithoutForm';
import ToggleBox from 'components/ToggleBox';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import { DATE_FORMAT, DISPLAY_DATE_CALENDAR_FORMAT } from 'consts';

import types from './types';

const STRING = 'string';
const DATE = 'date';

const operatorOptions = [
  {
    text: '∋ (contains)',
    value: types.CONTAINS,
  },
  {
    text: '∌ (does not contain)',
    value: types.NOT_CONTAINS,
  },
  {
    text: '= (equals)',
    value: types.EQUALS,
  },
  {
    text: '≠ (not equals)',
    value: types.NOT_EQUALS,
  },
  {
    text: '∃ (exists)',
    value: types.EXISTS,
  },
  {
    text: '∄ (does not exist)',
    value: types.NOT_EXISTS,
  },
];


export default function UserPropertyInput({
  value = {
    name: 'email',
    dataType: STRING,
    operator: types.EQUALS,
    value: '',
  },
  onChange = () => { },
  nameOptions = [
    {
      text: 'Email',
      value: 'email',
      type: STRING,
    },
    {
      text: 'Phone',
      value: 'phone',
      type: STRING,
    },
    {
      text: 'Birthday',
      value: 'dob',
      type: DATE,
    },
  ],
}) {
  const wrapper = useRef(null);
  const dateWrapper = useRef(null);

  const handleSelectNameChange = (fieldValue) => {
    const name = fieldValue;

    const item = nameOptions.find(item => item.value === fieldValue);

    if (item.type === DATE) {
      const today = moment().format(DATE_FORMAT);

      onChange({
        ...value,
        name: name,
        dataType: item.type,
        operator: types.BETWEEN,
        value: [today, today],
      });
    } else {
      onChange({
        ...value,
        name: name,
        dataType: item.type,
        value: '',
      });
    }
  };

  const handleSelectOperatorChange = (fieldValue) => {
    const newValue = { ...value };

    newValue.operator = fieldValue;

    onChange(newValue);
  };

  const onDateChange = (newValue) => {
    onChange({
      ...value,
      value: newValue,
    });
  };

  const handleValueChange = (event) => {
    onChange({
      ...value,
      value: event.target.value,
    });
  };

  const closeDateFilter = () => {
    if (wrapper.current) {
      wrapper.current.toggle();
    }
  };

  const onClose = () => {
    if (dateWrapper.current) {
      dateWrapper.current.reset();
    }
  };

  const renderInput = () => {
    if (value.dataType === DATE) {
      return (
        <ToggleBox ref={wrapper} position="right" onClose={onClose}>
          <ToggleBox.Button>
            <div className="input-style bg-white rounded border border-gray-300 cursor-pointer py-1 px-3 ms-1">
              <span>{moment(value.value[0]).format(DISPLAY_DATE_CALENDAR_FORMAT)} - {moment(value.value[1]).format(DISPLAY_DATE_CALENDAR_FORMAT)}</span>
            </div>
          </ToggleBox.Button>

          <ToggleBox.Content>
            <DateFilter ref={dateWrapper} onChange={onDateChange} onClose={closeDateFilter} value={value.value} />
          </ToggleBox.Content>
        </ToggleBox>
      );
    }

    return (
      <>
        <div>
          <TailwindComboboxWithoutForm items={operatorOptions} onChange={handleSelectOperatorChange} />
        </div>

        {
          (value.operator !== types.EXISTS && value.operator !== types.NOT_EXISTS) &&
          <div>
            <input
              value={value.value}
              onChange={handleValueChange}
              className="ds-input"
            />
          </div>
        }
      </>
    )
  };

  return (
    <div className='flex justify-start items-center w-full gap-4'>
    <div>
        <TailwindComboboxWithoutForm items={nameOptions} onChange={handleSelectNameChange} />
      </div>
      <>
        {renderInput()}
      </>
    </div>
  )
}
