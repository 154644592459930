import TextComboBox from 'components/TextComboBox/WithoutForm';
import TailwindComboboxWithoutForm from "components/TailwindCombobox/TailwindComboboxWithoutForm";

import types from './types';

const operatorOptions = [
  {
    text: '∋ (contains)',
    value: types.CONTAINS,
  },
  {
    text: '∌ (does not contain)',
    value: types.NOT_CONTAINS,
  },
  {
    text: '= (equals)',
    value: types.EQUALS,
  },
  {
    text: '≠ (not equals)',
    value: types.NOT_EQUALS,
  },
];


export default function AppFieldInput({
  value = {
    name: 'Email',
    operator: types.EQUALS,
  },
  onChange = () => { },
  nameOptions = ['App Version', 'Make', 'Model', 'OS Version', 'SDK Version'],
  valueOptions = ['value1', 'value2', 'value3'],
}) {
  const handleSelectNameChange = (fieldValue) => {
    const newValue = { ...value };

    newValue.name = fieldValue;

    onChange(newValue);
  };

  const handleSelectOperatorChange = (fieldValue) => {
    const newValue = { ...value };

    newValue.operator = fieldValue;

    onChange(newValue);
  };

  const handleTextComboBoxChange = (newValue) => {
    onChange({
      ...value,
      value: newValue,
    });
  };

  const handleValueChange = (event) => {
    onChange({
      ...value,
      value: event.target.value,
    });
  };

  return (
    <div className='flex justify-start items-center w-full'>

      <TailwindComboboxWithoutForm items={nameOptions} onChange={handleSelectNameChange} />

      <TailwindComboboxWithoutForm items={operatorOptions} onChange={handleSelectOperatorChange} />

      {Array.isArray(valueOptions) && valueOptions.length > 0 ?
        <TextComboBox
          value={value.value}
          onChange={handleTextComboBoxChange}
          className="input-style"
          options={valueOptions}
        />
        :
        <input
          value={value.value}
          onChange={handleValueChange}
          className="ds-input"
        />
      }
    </div>
  )
}
