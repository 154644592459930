import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch } from "react-redux";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { showError } from "components/FlashMessage/flashMessageSlice";

import {PATHS} from "consts";
import Visualisation from "views/dashboard_list/dashboard_detail/card_visualisation";
import VisualisationAddNew from "views/dashboard_list/dashboard_detail/card_visualisation_add_new"
import DashboardSvc from "services/dashboard";
import CardSvc from "services/card";


export default function Dashboard() {
  const dispatch = useDispatch();

  const dashboardId = useParams();
  const [dashboard, setDashboard] = useState({});
  const [cards, setCards] = useState([]);

  const getDashboard = async () => {
    try {
      const result = await DashboardSvc.getDashboardDetail(dashboardId);
      setDashboard(result);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  const listCardsByDashboardId = async () => {
    try {
      const result = await CardSvc.listCardsByDashboardId(dashboardId);
      result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      const parsedData = result.map(item => ({
        ...item,
        payload: JSON.parse(item.payload) // Parse the payload string into a JSON object
      }));
      setCards(parsedData);
    } catch (error) {
      const { message } = error;
      dispatch(showError({ message }));
    }
  }

  useEffect(() => {
    getDashboard();
    listCardsByDashboardId();
  }, [dashboardId]);


  return (
    <div id="dashboard">
      <div className="ds-input-text mt-3">
        <Link
          to={`${PATHS.APP}${PATHS.DASHBOARD}`}
          className="ds-input-text flex items-center text-sm hover:underline"
        >
          <FontAwesomeIcon icon={faAngleLeft}/>
          <p className={"ml-2"}>
            All dashboards
          </p>
        </Link>
      </div>

      <div className="ds-text-page-title mt-3">{dashboard.name}</div>

      <div className="w-full mt-5">
        <div className="grid grid-cols-2 gap-4">
          {cards && cards.map((card, index) => (
            <div className="ds-block" key={card.id}>
              <Visualisation
                appId={card.payload.appId}
                card={card}
                chartType={card.payload.chartType}
                defaultChartType={card.payload.defaultChartType}
                globalFilter={card.payload.globalFilter}
                chartId={card.payload.chartId}
                dimensionValue={card.payload.dimensionValue}
                limitValue={card.payload.limitValue}
                showDelete={true}
                frequencyValue={card.payload.frequencyValue}
                metricValue={card.payload.metricValue}
                orderValue={card.payload.orderValue}
                reset={listCardsByDashboardId}
                title={true}
                // onDelete={() => removeChartCustom(index)}
              />
            </div>
          ))}

          <div className="ds-block">
            <VisualisationAddNew/>
          </div>
        </div>
      </div>
    </div>
  )
}
