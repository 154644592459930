import HttpMethod from 'consts/httpMethod';
import {processRequest, processSuccessResponse, processErrorResponse, isUndefinedOrEmpty} from 'utils';


export default {
  async getFieldList({appId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: '/chart/field/list',
      method: HttpMethod.POST
    };
    try {
      const res = await processRequest(ep, {data: {appId}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async listCardsByDashboardId({dashboardId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/dashboard/${dashboardId}/card/list`,
      method: HttpMethod.GET
    };
    try {
      const res = await processRequest(ep, {data: {dashboardId}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },

  async deleteCard({id, dashboardId}) {
    const ep = {
      baseURL: process.env.REACT_APP_API_ROOT,
      url: `/dashboard/${dashboardId}/card/${id}/delete`,
      method: HttpMethod.DELETE
    };
    try {
      const res = await processRequest(ep, {data: {id, dashboardId}});
      return processSuccessResponse(res);
    } catch (e) {
      processErrorResponse(e.response);
      return [];
    }
  },
}
