import { Header } from "views/landing_page/home/Header";
import { Footer } from "views/landing_page/home/Footer";
import { DsIconApple, DsIconGoogleAds, DsIconTiktok } from "components/DsIcon";
import DsIconFacebook from "components/DsIcon/DsIconFacebook";
import DsIconHaravan from "components/DsIcon/DsIconHaravan";
import DsIconShopify from "components/DsIcon/DsIconShopify";
import DsIconRocket from "components/DsIcon/DsIconRocket";
import DsIconTime from "components/DsIcon/DsIconTime";
import TailwindBackground from "components/TailwindBackground";
import ShopMetricPricing from "views/landing_page/shopmetric/pricing";
import { Faqs } from "views/landing_page/home/Faqs";
import DsIconPieChart from "components/DsIcon/DsIconPieChart";
import DsIconChart from "components/DsIcon/DsIconChart";
import DsIconConnect from "components/DsIcon/DsIconConnect";
import DsIconClock from "components/DsIcon/DsIconClock";
export default function GrowthAnalytics() {
  return (
    <>
      <div
        style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <TailwindBackground classNames={"fixed top-0 right-0 w-1/4 h-auto"} />
        <TailwindBackground classNames={"fixed bottom-0 left-0 w-1/4 h-auto"} />
        <div>
          <Header classNames={"bg-red"} />
          <main className="pb-16 pt-56 lg:pt-56 flex justify-center items-center">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className={"grid grid-rows-10 gap-10"}>
                <div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-20 items-center mb-10">
                    <div>
                      <div
                        className={
                          "mx-auto max-w-4xl lp-text-title-bg-light tracking-tight"
                        }
                      >
                        <p>Your Trusted Growth Analytics Provider</p>
                      </div>
                      <div className={"lp-text-desc-bg-light mt-8"}>
                        Get complete visibility into your marketing with ROI
                        tracking, AI-driven optimization, and advanced
                        analytics—all while safeguarding against ad fraud.
                      </div>
                    </div>
                    <div className="flex justify-end h-auto w-full">
                      <img
                        className="w-full h-auto lg:ml-14"
                        src={"https://static.datasenses.io/roi.png"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>

                <div className="mx-auto max-w-7xl px-6 lg:px-8 glass-effect">
                  <div className={"p-16 "}>
                    <div className={"grid grid-rows-3 gap-20"}>
                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <div className={"lp-text-title-3"}>
                              Return On Investment Measurement
                            </div>
                            <div className={"lp-text-title-4"}>
                              Measure impact of every single dollar you spend
                              across all channels. Monitor at Product level, not
                              only campaign level Monitor at KOL level, not only
                              channel level
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-end sm:justify-center"}>
                            <img
                              className="h-80 w-auto"
                              src={"https://static.datasenses.io/roi.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <div className={"lp-text-title-3"}>
                              Online To Offline Solution
                            </div>
                            <div className={"lp-text-title-4"}>
                              This type of solution typically involves
                              generating a deep link that users can click, which
                              then directs them to specific content or actions
                              within an app or website.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-end sm:justify-center"}>
                            <img
                              className="h-80 w-auto"
                              src={
                                "https://static.datasenses.io/datasenses_qr.png"
                              }
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <div className={"lp-text-title-3"}>
                              Understand Customer
                            </div>
                            <div className={"lp-text-title-4"}>
                              Look at your business from customers' point of
                              views. Who are the most valuable customers?
                              Support Funnel analysis Support Cohort analysis
                              Support Customer Segmentation analysis
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-end sm:justify-center"}>
                            <img
                              className="h-80 w-auto"
                              src={"https://static.datasenses.io/ds_funnel.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <div className={"lp-text-title-3"}>
                              Recommendation{" "}
                            </div>
                            <div className={"lp-text-title-4"}>
                              Leverage Data & AI to control your budget and
                              optimize your campaigns.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-end sm:justify-center"}>
                            <img
                              className="h-80 w-auto"
                              src={"	https://static.datasenses.io/customer.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>

                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={
                            "flex lg:justify-start sm:justify-start items-center"
                          }
                        >
                          <div>
                            <div className={"lp-text-title-3"}>
                              Fraud Detection System
                            </div>
                            <div className={"lp-text-title-4"}>
                              Detect & prevent ad fraud of the app install
                              journey, abusers who are unusual earning the
                              interest from your business.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-end sm:justify-center"}>
                            <img
                              className="h-80 w-auto"
                              src={"	https://static.datasenses.io/fraud.png"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Faqs />
          <Footer />
        </div>
      </div>
    </>
  );
}
