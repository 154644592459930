export const CONTAINS = 'contains';
export const NOT_CONTAINS = 'not_contains';
export const EQUALS = '=';
export const NOT_EQUALS = '!=';
export const EXISTS = 'exists';
export const NOT_EXISTS = 'not_exist';
export const IN = 'IN';
export const BETWEEN = 'BETWEEN';
export const EQUALS_OR_GREATER_THAN = '>=';
export const EQUALS_OR_LESS_THAN = '<=';

export const whereValue = {
  PREDOMINANTLY: 'predominantly',
  AT_LEAST: 'at least',
};

export const hourValue = {
  '12_AM': '12_AM',
  '1_AM': '1_AM',
  '2_AM': '2_AM',
  '3_AM': '3_AM',
  '4_AM': '4_AM',
  '5_AM': '5_AM',
  '6_AM': '6_AM',
  '7_AM': '7_AM',
  '8_AM': '8_AM',
  '9_AM': '9_AM',
  '10_AM': '10_AM',
  '11_AM': '11_AM',
  '12_PM': '12_PM',
  '1_PM': '1_PM',
  '2_PM': '2_PM',
  '3_PM': '3_PM',
  '4_PM': '4_PM',
  '5_PM': '5_PM',
  '6_PM': '6_PM',
  '7_PM': '7_PM',
  '8_PM': '8_PM',
  '9_PM': '9_PM',
  '10_PM': '10_PM',
  '11_PM': '11_PM',
};

export const roleOptions = [
  {
    name: 'Admin',
    fieldKey: 'Admin',
  },
  {
    name: 'Editor',
    fieldKey: 'Editor',
  },
  {
    name: 'Viewer',
    fieldKey: 'Viewer',
  }
]

export const showDataOptions = [
  {
    name: 'Only Valid Data',
    fieldKey: true,
  },
  {
    name: 'All Data',
    fieldKey: false,
  }
]

export const metricOptions = [
  {
    name: 'Impressions',
    value: 'ad_impressions',
    fieldKey: 'ad_impressions',
  },
  {
    name: 'Spend',
    value: 'ad_spend',
    fieldKey: 'ad_spend',
  },
  {
    name: 'Clicks',
    value: 'ad_clicks',
    fieldKey: 'ad_clicks',
  },
  {
    name: 'Revenue',
    value: 'revenue',
    fieldKey: 'revenue',
  },
  {
    name: 'ROI',
    value: 'roi',
    fieldKey: 'roi',
  },
]

export const propertyInputType = {
  USER_PROPERTIES: 'User Properties',
  DEMOGRAPHICS: 'Demographics',
  GEOGRAPHY: 'Geography',
  TECHNOGRAPHICS: 'Technographics',
  SUBSCRIPTION_GROUP: 'Subscription Group',
  REACHABILITY: 'Reachability',
  APP_FIELDS: 'App Fields',
  SEGMENTS: 'Segments',
};

export const limitOptions = [
  {fieldKey: '5', value: '5', name: '5'},
  {fieldKey: '10', value: '10', name: '10'},
  {fieldKey: '20', value: '20', name: '20'},
  {fieldKey: '50', value: '50', name: '50'},
  {fieldKey: '100', value: '100', name: '100'},
  {fieldKey: 'All', value: 'All', name: 'All'},
]

export const orderOptions = [
  {fieldKey: ' DESC', value: 'DESC', name: 'Descending'},
  {fieldKey: ' ASC', value: 'ASC', name: 'Ascending'},
]

export const sessionOptions = [
  {fieldKey: 'FLOOR(hour / 2) * 2', value: 'Hour', name: 'Session'},
]

export const geographyOptions = [
  {fieldKey: 'city', value: 'City', name: 'City'},
  {fieldKey: 'timezone', value: 'Timezone', name: 'Timezone'},
]

export const frequencyOptions = [
  {fieldKey: 'date_key', value: 'date_key', name: 'Daily'},
  {fieldKey: 'DATE_TRUNC(date_key, WEEK)', value: 'DATE_TRUNC(date_key, WEEK)', name: 'Weekly'},
  {fieldKey: 'DATE_TRUNC(date_key, MONTH)', value: 'DATE_TRUNC(date_key, MONTH)', name: 'Monthly'},
]

export const eventDetailFields = [
  {
    "name": "event_name",
    "fieldKey": "event_name",
    "fieldType": "string",
    "attribute": "dimension",
    "filterable": true,
    "isSelectedOption": true
  },
  {
    "name": "Date",
    "fieldKey": "date_key",
    "fieldType": "Date",
    "attribute": "dimension",
    "filterable": true,
    "isSelectedOption": false
  },
  {
    "name": "Count Event",
    "fieldKey": "count_event",
    "fieldType": "number",
    "attribute": "metric",
    "filterable": false,
    "isSelectedOption": true
  }
]


export default {


  FIELD_CHART_TYPE: "chart_type",
  FIELD_CHART_NAME: "chart_name",
  FIELD_GROUP_BY: "group_by",
  FIELD_GLOBAL_FILTERS: "global_filters",
  FIELD_ORDER_BY: "order_by",
  FIELD_LIMIT: "limit",
  FIELD_METRIC: "metric",
  FIELD_DIMENSION: "dimension",
  FIELD_NAME: "name",
  FIELD_VALUE: "value",

  CHART_TYPE_KPI: "kpi",
  CHART_NAME_KPI_CHART_ID: "kpi_1",
  CHART_TYPE_LINECHART: "line_chart",
  CHART_TYPE_TABLE: "table",

  CHART_1: "chart_1",
  CHART_2: "chart_2",
  CHART_3: "chart_3",
  CHART_4: "chart_4",
  CHART_5: "chart_5",
  CHART_6: "chart_6",
  CHART_7: "chart_7",
  CHART_CUSTOM: "chart_custom",

  CHART_TYPE_BARCHART: "bar_chart",
  CHART_TYPE_STACKEDCHART: "stacked_chart",
  DELETE_CHART: "delete_chart",
  CHART_DATE_FIELD: "date_key",
  CHART_FILTER: "filter",
  CHART_SUB_FILTER: "sub_filter",
  CHART_KPI_METRIC: [
    {displayedName: "Total installs", name: "installs", value: "installs", fieldKey: "installs"},
    {
      displayedName: "Organic installs",
      name: "organic_installs",
      value: "organic_installs",
      fieldKey: "organic_installs"
    },
    {displayedName: "Session", name: "sessions", value: "sessions", fieldKey: "sessions"},
    {displayedName: "User", name: "users", value: "users", fieldKey: "users"},
    {displayedName: "Ad Spend", name: "ad_spend", value: "ad_spend", fieldKey: "ad_spend"},
    {displayedName: "Revenue", name: "revenue", value: "revenue", fieldKey: "revenue"},
  ],
  ADS_CHART_KPI_METRIC: [
    {displayedName: "Impressions", name: "ad_impressions"},
    {displayedName: "Clicks", name: "ad_clicks"},
    {displayedName: "Spend", name: "ad_spend"},
    {displayedName: "Revenue", name: "revenue"},
    {displayedName: "ROI", name: "roi"},
  ],
  CHART_KPI_SKAN_METRIC: [
    {displayedName: "Total installs", name: "installs"},
    {displayedName: "SKAN installs", name: "skan_installs"},
    {displayedName: "Session", name: "sessions"},
    {displayedName: "User", name: "users"},
  ],
  CHART_KPI_FRAUD_METRIC: [
    {displayedName: "Fraud installs", name: "installs"},
    {displayedName: "Fraud Session", name: "sessions"},
    {displayedName: "Fraud User", name: "users"},
  ],
  FIELD_EVENT_NAME: "event_name",
  FIELD_EVENT_PROPERTIES: "event_properties",
  METRIC_COUNT_EVENT: "count_event",
  SEGMENT_CAMPAIGN_FIELD: "campaign",
  OPERATOR_EQUAL: "=",
  OPERATOR_CONTAIN: "contains",

  OPERATORS: [
    {
      text: '∋ (contains)',
      value: CONTAINS,
    },
    {
      text: '∌ (does not contain)',
      value: NOT_CONTAINS,
    },
    {
      text: '= (equals)',
      value: EQUALS,
    },
    {
      text: '≠ (not equals)',
      value: NOT_EQUALS,
    },
    {
      text: '∃ (exists)',
      value: EXISTS,
    },
    {
      text: '∄ (does not exist)',
      value: NOT_EXISTS,
    },
  ],
  OPERATORS_LIST: [
    {
      name: '∋ (contains)',
      fieldKey: CONTAINS,
    },
    {
      name: '∌ (does not contain)',
      fieldKey: NOT_CONTAINS,
    },
    {
      name: '= (equals)',
      fieldKey: EQUALS,
    },
    {
      name: '≠ (not equals)',
      fieldKey: NOT_EQUALS,
    },
    {
      name: '∃ (exists)',
      fieldKey: EXISTS,
    },
    {
      name: '∄ (does not exist)',
      fieldKey: NOT_EXISTS,
    },
  ],
  FIELD_LIST: [
    {
      name: 'Hour',
      fieldKey: 'hour',
    },
    {
      name: 'Day of Week',
      fieldKey: 'day_of_week',
    },
    {
      name: 'Gender',
      fieldKey: 'gender',
    },
    {
      name: 'Platform',
      fieldKey: 'platform',
    },
    {
      name: 'City',
      fieldKey: 'city',
    }
  ],
  DAY_OF_WEEK_OPTIONS: [
    {
      text: 'Sunday',
      value: 'sunday',
    },
    {
      text: 'Monday',
      value: 'monday',
    },
    {
      text: 'Tuesday',
      value: 'tuesday',
    },
    {
      text: 'Wednesday',
      value: 'wednesday',
    },
    {
      text: 'Thursday',
      value: 'thursday',
    },
    {
      text: 'Friday',
      value: 'friday',
    },
    {
      text: 'Saturday',
      value: 'saturday',
    },
  ],
  HOUR_OPTIONS: [
    {
      text: '12 AM',
      value: hourValue['12_AM'],
    },
    {
      text: '1 AM',
      value: hourValue['1_AM'],
    },
    {
      text: '2 AM',
      value: hourValue['2_AM'],
    },
    {
      text: '3 AM',
      value: hourValue['3_AM'],
    },
    {
      text: '4 AM',
      value: hourValue['4_AM'],
    },
    {
      text: '5 AM',
      value: hourValue['5_AM'],
    },
    {
      text: '6 AM',
      value: hourValue['6_AM'],
    },
    {
      text: '7 AM',
      value: hourValue['7_AM'],
    },
    {
      text: '8 AM',
      value: hourValue['8_AM'],
    },
    {
      text: '9 AM',
      value: hourValue['9_AM'],
    },
    {
      text: '10 AM',
      value: hourValue['10_AM'],
    },
    {
      text: '11 AM',
      value: hourValue['11_AM'],
    },
    {
      text: '12 PM',
      value: hourValue['12_PM'],
    },
    {
      text: '1 PM',
      value: hourValue['1_PM'],
    },
    {
      text: '2 PM',
      value: hourValue['2_PM'],
    },
    {
      text: '3 PM',
      value: hourValue['3_PM'],
    },
    {
      text: '4 PM',
      value: hourValue['4_PM'],
    },
    {
      text: '5 PM',
      value: hourValue['5_PM'],
    },
    {
      text: '6 PM',
      value: hourValue['6_PM'],
    },
    {
      text: '7 PM',
      value: hourValue['7_PM'],
    },
    {
      text: '8 PM',
      value: hourValue['8_PM'],
    },
    {
      text: '9 PM',
      value: hourValue['9_PM'],
    },
    {
      text: '10 PM',
      value: hourValue['10_PM'],
    },
    {
      text: '11 PM',
      value: hourValue['11_PM'],
    },
  ],
  FREQUENCY_OPTIONS: [
    {
      text: 'predominantly',
      value: whereValue.PREDOMINANTLY,
    },
    {
      text: 'at least',
      value: whereValue.AT_LEAST,
    },
  ],
  COMMON_PROPERTY_OPTIONS: [
    {
      text: 'User Properties',
      value: propertyInputType.USER_PROPERTIES,
    },
    // {
    //   text: 'Demographics',
    //   value: types.DEMOGRAPHICS,
    // },
    // {
    //   text: 'Geography',
    //   value: types.GEOGRAPHY,
    // },
    // {
    //   text: 'Technographics',
    //   value: types.TECHNOGRAPHICS,
    // },
    // {
    //   text: 'Reachability',
    //   value: types.REACHABILITY,
    // },
    // {
    //   text: 'App Fields',
    //   value: types.APP_FIELDS,
    // },
    // {
    //   text: 'Segments',
    //   value: types.SEGMENTS,
    // },
  ],
}




