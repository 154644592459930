import {useState, useEffect} from 'react';
import {titleCase} from "utils/util";

/**
 * FieldFilter component is used to manage field filters.
 * @param {Object} props - The props object.
 * @param {Array} [props.value=[]] - The selected values for the field, defaults to an empty array.
 * @param {Function} [props.onChange=() => {}] - The function called when the selected values change.
 * @returns {JSX.Element} The JSX representation of the FieldFilter component.
 */
export default function FieldFilter({
                                      value = [],
                                      onChange = () => {
                                      },
                                      options = [],
                                    }) {

  const [defaultSelected, setDefaultSelected] = useState(value);
  const checkBoxClick = (value) => {
    // Ensure defaultSelected is always an array
    const updatedArray = Array.isArray(defaultSelected)
      ? [...defaultSelected]
      : [defaultSelected];

    const indexToRemove = updatedArray.indexOf(value);

    if (indexToRemove === -1) {
      updatedArray.push(value);
    } else {
      updatedArray.splice(indexToRemove, 1);
    }

    setDefaultSelected(updatedArray);
  }

  useEffect(() => {
    onChange(defaultSelected);
  }, [defaultSelected]);

  useEffect(() => {
    setDefaultSelected(value);
  }, [value]);

  return (
    <div>
      {Array.isArray(options) && options.map((item, index) => (
        <div className={"hover:bg-slate-100 cursor-pointer"}
             onClick={() => checkBoxClick(item.value)}
             key={index}
        >
          <div className="items-center w-max p-2"
          >
            <div className={"flex"}>
              <input
                type="checkbox"
                id={item.fieldKey}
                value={item.value}
                checked={defaultSelected.includes(item.value)}
                onChange={() => {
                }}
              />
              <div className={"ds-input-text ml-2"}>
                {titleCase(item.value)}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
