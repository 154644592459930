import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';

import Input from './input';

import './index.scss';
import {useEffect} from "react";

export default function UserWhoDid({
  name,
  className,
  defaultValue = {
    operator: "AND",
    value: [],
  }
}) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const { field } = useController({ name, control, defaultValue });

  const onChange = (value) => {
    field.onChange(value);
  };

  useEffect(() => {
    onChange(defaultValue);
  }, [JSON.stringify(defaultValue)]);

  return (
    <div className={`relative grid grid-cols-1 gap-2 ${className}`}>
      <div className="flex items-center justify-start">
        <span className="ds-text-form-title">{t('USERS_WHO_DID')}</span>
        <span className="ml-1 ds-text-lighter">({t('OPTIONAL')})</span>
      </div>
      <div className="flex items-center justify-start">
        <Input onChange={onChange} value={field.value} />
      </div>
    </div>
  );
}
