import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PATHS } from "consts";

import { getApps } from "store/appSlice";
import { Header } from "views/landing_page/home/Header";

import { Hero } from "./Hero";
import { PrimaryFeatures } from "./PrimaryFeatures";
import { CallToAction } from "./CallToAction";
import { Pricing } from "../pricing";
import { Faqs } from "./Faqs";
import { Footer } from "./Footer";
import LPTextBlock from "../LPTextBlock";

import TailwindBackground from "components/TailwindBackground";

import React from "react";
import { RequestDemoForm } from "../../request_demo";
import RequestForm from "views/request_demo/RequestForm";

export default function LandingHome() {
  const dispatch = useDispatch();
  const isLogin = useSelector((state) => !!state.user.refreshToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate(PATHS.HOMEPAGE);
    }
  }, [isLogin]);

  // check token expired
  useEffect(() => {
    dispatch(getApps());
  }, []);
  return (
    <>
      <div style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <Header />
        <div className={"px-2"}>
          <main className="pt-56 lg:pt-56">
            <Hero/>
            <div className={"grid grid-rows-1 pt-32 gap-16 mx-auto max-w-7xl"}>
              <div className={"grid grid-cols-1"}>
                <div>
                  <img
                    className="lg:max-w-7xl h-auto mx-auto"
                    src={"https://static.datasenses.io/ads-flow.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className={"grid grid-cols-1 lg:grid-cols-2"}>
                <div className="flex flex-col items-center justify-center">
                  <LPTextBlock
                    title={"Growth Analytics Provider"}
                    description={
                      <>
                        Gain full visibility into your marketing performance with ROI tracking, advanced customer
                        analytics, AI-powered campaign optimization, and seamless online-to-offline integration, all
                        while protecting against ad fraud.
                      </>
                    }
                    type={"bg_light"}
                    link={"/request-demo"}
                  />
                </div>
                <div>
                  <img
                    className="w-full h-auto"
                    src={"https://static.datasenses.io/roi.png"}
                    alt=""
                  />
                </div>
              </div>
              <div className={"grid grid-cols-1 lg:grid-cols-2 gap-8 glass-effect"}>
                <div
                  className={
                    "flex lg:justify-start justify-center items-center"
                  }
                >
                  <img
                    className="w-120 h-120"
                    src={"https://static.datasenses.io/ShopMetric.gif"}
                    alt={""}
                  />
                </div>

                <div className={"flex items-center justify-center"}>
                  <LPTextBlock
                    title={"Shop Metrics"}
                    description={
                      "One-click integration with major eCommerce platforms and ad networks. Streamline your data and focus on growth."
                    }
                    type={"bg_light"}
                    link={"/request-demo"}
                  />
                </div>
              </div>
              <div>
                <RequestForm/>
              </div>
              <div>
                <Faqs/>
              </div>
            </div>
          </main>
          <Footer/>
        </div>
      </div>
    </>
  );
}
