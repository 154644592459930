import DsListBox from "components/DsListBox";
import {useEffect, useMemo, useState} from "react";
import {buildAppList} from "utils/app";
import {CARD_SUBTYPE, CARD_TYPE, CHART_CONFIG, PATHS} from "consts";
import {useDispatch, useSelector} from "react-redux";
import {FormProvider, useForm} from "react-hook-form";
import DateFilterToggleBox from "components/DateFilterToggleBox";
import MetaDataService from "services/metadata";
import MetadataService from "services/metadata";
import {showError} from "components/FlashMessage/flashMessageSlice";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {buildGlobalFilterRequest} from "views/visualisation/chartProcessor";
import Visualisation from "views/overview/visualisation";
import {
  eventDetailFields,
  frequencyOptions,
  geographyOptions,
  limitOptions,
  orderOptions,
  sessionOptions,
} from "consts/chartConfig";
import TailwindCombobox from "components/TailwindCombobox";
import {tabLink, tabs} from "views/analytics/events/tabs";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function EventsAnalysis() {
  const [hashValue, setHashValue] = useState(window.location.hash || tabLink.TREND_AND_PROPERTIES);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const appList = useSelector((state) => state.app.appList);
  const methods = useForm();
  const {appId} = useParams();
  const fieldList = eventDetailFields
  const [paramEventNameOptions, setParamEventNameOptions] = useState([]);
  const [globalFilterRequest, setGlobalFilterRequest] = useState([]);

  const [eventProperties, setEventProperties] = useState([]);

  useEffect(() => {
    const handleHashChange = () => {
      setHashValue(window.location.hash);
    };

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);


  const getEventProperties = async (eventName) => {
    const result = await MetadataService.getFields2({
      appId: appId,
      fieldKey: eventName
    });
    setEventProperties(result.map((value) => ({
      name: value.fieldKey,
      fieldKey: `JSON_EXTRACT_SCALAR(event_properties, '$.${value.fieldKey}')`,
      value: value.name
    })));
  }

  const chartDimensions = useMemo(() => {
    const value = fieldList.filter(
      (element) => element.attribute === "dimension" && element.isSelectedOption
    );
    return value
      ? value.map((item) => ({name: item.name, fieldKey: item.fieldKey}))
      : [];
  }, [fieldList]);

  const chartMetrics = useMemo(() => {
    const value = fieldList.filter(
      (element) => element.attribute === "metric" && element.isSelectedOption
    );
    return value
      ? value.map((item) => ({name: item.name, fieldKey: item.fieldKey}))
      : [];
  }, [fieldList]);

  const getParamEventNameOptions = async () => {
    try {
      const result = await MetaDataService.getEventNameList({appId});
      setParamEventNameOptions(result.map((value) => ({name: value, fieldKey: value, value})));
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  useEffect(() => {
    getParamEventNameOptions();
  }, [appId]);

  const appOptions = useMemo(() => {
    return buildAppList(appList, PATHS.EVENTS2);
  }, [appList]);

  const onSubmit = async (data) => {
    try {
      data[CHART_CONFIG.FIELD_GLOBAL_FILTERS][CHART_CONFIG.FIELD_EVENT_NAME] = [data[CHART_CONFIG.FIELD_GLOBAL_FILTERS][CHART_CONFIG.FIELD_EVENT_NAME]];
      const globalFiterRequest = buildGlobalFilterRequest(data);
      setGlobalFilterRequest(globalFiterRequest);
    } catch (error) {
      const {message} = error;
      dispatch(showError({message}));
    }
  };

  useEffect(() => {
    methods.handleSubmit(onSubmit)();
  }, [chartMetrics, chartDimensions]);

  useEffect(() => {
    getEventProperties(methods.watch(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.event_name`));
    methods.handleSubmit(onSubmit)();
  }, [JSON.stringify(methods.watch())])

  useEffect(() => {
    getEventProperties(methods.getValues(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.event_name`));
  }, [JSON.stringify(methods.watch(`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.event_name`))]);


  return (
    <div id="eventanalysis">
      <DsListBox listData={appOptions}/>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-row w-full mt-5">
            <div className="mr-4">
              <DateFilterToggleBox
                name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.${CHART_CONFIG.CHART_DATE_FIELD}`}
              />
            </div>

            {/*<EventInput*/}
            {/*  value={event}*/}
            {/*  onChange={(value) => handleEventChange(value)}*/}
            {/*  options={paramEventNameOptions}*/}
            {/*  className="mb-10"*/}
            {/*/>*/}

            <TailwindCombobox
              name={`${CHART_CONFIG.FIELD_GLOBAL_FILTERS}.event_name`}
              items={paramEventNameOptions}
            />
          </div>
          <div className="mt-4 sticky">
            <div className="hidden sm:block">
              <nav className="-mb-px flex space-x-8 ">
                {tabs.map((tab) => (<a
                  id={tab.name}
                  key={tab.name}
                  href={tab.href}
                  className={classNames(hashValue === tab.href ? `border-[#8b5cf6] ds-text-color ds-tab-color` : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700', 'whitespace-nowrap select-none cursor-pointer border-b-2 px-1 pb-2 text-sm font-medium')}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {t(tab.name)}
                </a>))}
              </nav>

              <div className="flex-auto">
                <div className="tab-content tab-space">
                  {hashValue === tabLink.TREND_AND_PROPERTIES &&
                    <div className="ds-block w-full mt-5">
                      {chartDimensions.length > 0 && chartMetrics.length > 0 && (
                        <div>
                          <Visualisation
                            appId={appId}
                            defaultChartType={CHART_CONFIG.CHART_TYPE_LINECHART}
                            globalFilter={globalFilterRequest}
                            chartId={CHART_CONFIG.CHART_1}
                            chartIndex={0}
                            dimensions={chartDimensions}
                            metrics={chartMetrics}
                            frequencyOptions={frequencyOptions}
                            cardType={CARD_TYPE.CARD_TYPE_EVENT}
                            cardSubType={CARD_SUBTYPE.CARD_SUBTYPE_EVENT_TREND}
                          />

                          <div className="mt-8">
                            <Visualisation
                              appId={appId}
                              defaultChartType={CHART_CONFIG.CHART_TYPE_BARCHART}
                              globalFilter={globalFilterRequest}
                              chartId={CHART_CONFIG.CHART_2}
                              chartIndex={1}
                              dimensions={eventProperties}
                              metrics={chartMetrics}
                              limitOptions={limitOptions}
                              orderOptions={orderOptions}
                              cardType={CARD_TYPE.CARD_TYPE_EVENT}
                              cardSubType={CARD_SUBTYPE.CARD_SUBTYPE_EVENT_TREND}
                            />
                          </div>

                        </div>
                      )}
                    </div>
                  }
                  {hashValue === tabLink.SESSION &&
                    <div className="ds-block w-full mt-5">
                      {chartDimensions.length > 0 && chartMetrics.length > 0 && (
                        <div>
                          <Visualisation
                            appId={appId}
                            defaultChartType={CHART_CONFIG.CHART_TYPE_BARCHART}
                            globalFilter={globalFilterRequest}
                            chartId={CHART_CONFIG.CHART_3}
                            chartIndex={2}
                            dimensions={sessionOptions}
                            metrics={chartMetrics}
                            cardType={CARD_TYPE.CARD_TYPE_EVENT}
                            cardSubType={CARD_SUBTYPE.CARD_SUBTYPE_EVENT_SESSION}
                          />
                        </div>
                      )}
                    </div>
                  }
                  {hashValue === tabLink.GEOGRAPHY &&
                    <div className="ds-block w-full mt-5">
                      {chartDimensions.length > 0 && chartMetrics.length > 0 && (
                        <div>
                          <Visualisation
                            appId={appId}
                            defaultChartType={CHART_CONFIG.CHART_TYPE_BARCHART}
                            globalFilter={globalFilterRequest}
                            chartId={CHART_CONFIG.CHART_4}
                            chartIndex={3}
                            dimensions={geographyOptions}
                            metrics={chartMetrics}
                            limitOptions={limitOptions}
                            orderOptions={orderOptions}
                            cardType={CARD_TYPE.CARD_TYPE_EVENT}
                            cardSubType={CARD_SUBTYPE.CARD_SUBTYPE_EVENT_GEOGRAPHY}
                          />
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>


        </form>
      </FormProvider>
    </div>
  )
}
