import { Header } from "views/landing_page/home/Header";
import { Footer } from "views/landing_page/home/Footer";
import { DsIconApple, DsIconGoogleAds, DsIconTiktok } from "components/DsIcon";
import DsIconFacebook from "components/DsIcon/DsIconFacebook";
import DsIconHaravan from "components/DsIcon/DsIconHaravan";
import DsIconShopify from "components/DsIcon/DsIconShopify";
import DsIconRocket from "components/DsIcon/DsIconRocket";
import DsIconTime from "components/DsIcon/DsIconTime";
import TailwindBackground from "components/TailwindBackground";
import ShopMetricPricing from "views/landing_page/shopmetric/pricing";
import { Faqs } from "views/landing_page/home/Faqs";
import DsIconPieChart from "components/DsIcon/DsIconPieChart";
import DsIconChart from "components/DsIcon/DsIconChart";
import DsIconConnect from "components/DsIcon/DsIconConnect";
import DsIconClock from "components/DsIcon/DsIconClock";
export default function ShopMetric() {
  return (
    <>
      <div
        style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <TailwindBackground classNames={"fixed top-0 right-0 w-1/4 h-auto"} />
        <TailwindBackground classNames={"fixed bottom-0 left-0 w-1/4 h-auto"} />
        <div>
          <Header classNames={"bg-red"} />
          <main className="pb-16 pt-56 lg:pt-56 flex justify-center items-center">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className={"grid grid-rows-10 gap-10"}>
                <div>
                  <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-20 items-center">
                    <div>
                      <div
                        className={
                          "mx-auto max-w-4xl lp-text-title-bg-light tracking-tight"
                        }
                      >
                        <p>Grow your shop with confidence</p>
                      </div>
                      <div className={"lp-text-desc-bg-light mt-8"}>
                        Let ShopMetric turn your messy data into valuable
                        insights, empowering you to make data-driven decisions.
                      </div>
                    </div>
                    <div className="flex justify-end h-auto w-3/4">
                      <img
                        className=""
                        src={"https://static.datasenses.io/ShopMetric.gif"}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <div
                    className="mt-10/80 p-16
                 mx-auto max-w-7xl px-6 lg:px-8 glass-effect"
                  >
                    <div
                      className={
                        "lp-text-title-bg-light mb-5 flex justify-center sm:px-10"
                      }
                    >
                      Integrate with the most popular platforms
                    </div>
                    <div className="grid grid-cols-6 mt-10 justify-items-center	 ">
                      <div className={"h-10 w-10"}>
                        <DsIconHaravan />
                      </div>
                      <div className={"h-10 w-10"}>
                        <DsIconGoogleAds />
                      </div>
                      <div className={"h-10 w-10"}>
                        <DsIconTiktok />
                      </div>
                      <div className={"h-10 w-10"}>
                        <DsIconFacebook />
                      </div>
                      <div className={"h-10 w-10"}>
                        <DsIconApple />
                      </div>
                      <div className={"h-10 w-10"}>
                        <DsIconShopify />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"p-16"}>
                  <div
                    className={
                      "lp-text-title-bg-light mb-5 flex justify-center"
                    }
                  >
                    Amazing Business Intelligence Platform
                  </div>
                  <div className={"flex justify-center"}>
                    <video autoPlay muted>
                      <source
                        src={"https://static.datasenses.io/olap.mp4"}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
                <div className="mx-auto max-w-7xl px-6 lg:px-8 glass-effect">
                  <div className={"p-16 "}>
                    <div
                      className={
                        "lp-text-title-bg-light mb-5 flex justify-center mt-10"
                      }
                    >
                      {" "}
                      How can ShopMetric help your business?
                    </div>
                    <div className="grid lg:grid-cols-3 sm:grid-cols-1 gap-8">
                      <div className={"ds-block"}>
                        <div className={"flex justify-center"}>
                          <div className={"h-12 w-12"}>
                            <DsIconClock />
                          </div>
                        </div>
                        <div className={"text-center font-bold text-lg mt-2"}>
                          Minimize Manual Tasks
                        </div>
                        <div className={"text-center text-gray-600 mt-2"}>
                          Cut down on the time and effort spent fetching data
                          from various sources.
                        </div>
                      </div>
                      <div className={"ds-block"}>
                        <div className={"flex justify-center"}>
                          <div className={"h-12 w-12"}>
                            <DsIconConnect />
                          </div>
                        </div>
                        <div className={"text-center font-bold text-lg mt-2"}>
                          Simple Data Integration
                        </div>
                        <div className={"text-center text-gray-600 mt-2"}>
                          Easily link your data sources to ShopMetric’s
                          integrated data warehouse.
                        </div>
                      </div>
                      <div className={"ds-block grid"}>
                        <div className={"flex justify-center"}>
                          <div className={"h-12 w-12"}>
                            <DsIconChart />
                          </div>
                        </div>
                        <div className={"text-center font-bold text-lg mt-2"}>
                          Quick Data Visualization
                        </div>
                        <div className={"text-center text-gray-600 mt-2"}>
                          Create sophisticated charts with zero processing time
                          using our intuitive drag-and-drop tools.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={"p-16 "}>
                    <div className={"grid grid-rows-3 gap-20"}>
                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div className={"flex justify-start"}>
                          <img
                            className="w-4/5"
                            src={"https://static.datasenses.io/clickshop.gif"}
                            alt=""
                          />
                        </div>
                        <div className={"flex lg:justify-end sm:justify-start items-center  gap-20"}>
                          <div>
                            <div className={"lp-text-title-3"}>
                              One click to connect a source
                            </div>
                            <div className={"lp-text-title-4"}>
                              Seamlessly integrate your data sources with just a
                              single click, eliminating the hassle of complex
                              setups and configurations. Experience unparalleled
                              convenience and efficiency as you bridge your
                              systems instantly, unlocking a world of
                              possibilities for data accessibility and
                              utilization.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 sm:grid-cols-1">
                        <div
                          className={"flex lg:justify-start sm:justify-start items-center  gap-20"}
                        >
                          <div>
                            <div className={"lp-text-title-3"}>
                              Zero time to extract data
                            </div>
                            <div className={"lp-text-title-4"}>
                              Instantly access and retrieve your desired data
                              without any waiting time or delays. Say farewell
                              to lengthy extraction processes and hello to
                              immediate insights. With zero time wasted, you can
                              focus on analyzing and utilizing your data to its
                              fullest potential, empowering your decision-making
                              and driving your business forward at unparalleled
                              speed.
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className={"flex justify-end"}>
                            <img
                              className="w-2/3 h-auto"
                              src={"https://static.datasenses.io/pipeline.gif"}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="grid lg:grid-cols-2 sm:grid-cols-1 items-center gap-20">
                       
                        <div>
                          <video autoPlay muted>
                            <source
                              src={"https://static.datasenses.io/olap.mp4"}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                        <div>
                          <div className={"lp-text-title-3"}>
                            After few minutes, Enjoy playing with data
                          </div>
                          <div className={"lp-text-title-4"}>
                            Dive into the world of data exploration and analysis
                            with our exceptional business intelligence tool.
                            Unlock the power of your data effortlessly as you
                            navigate through insightful visualizations,
                            interactive dashboards, and intuitive analytics
                            features. With our user-friendly interface and
                            robust functionality, uncover valuable insights and
                            make informed decisions with ease.
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                  <ShopMetricPricing />
                </div>
              </div>
            </div>
          </main>
          <Faqs />
          <Footer />
        </div>
      </div>
    </>
  );
}
