import { Fragment } from 'react';
import { Header } from "views/landing_page/home/Header";
import { CallToAction } from "views/landing_page/home/CallToAction";
import { Faqs } from "views/landing_page/home/Faqs";
import { Footer } from "views/landing_page/home/Footer";
import LPTextBlock from "views/landing_page/LPTextBlock";
import LPImageBlock from "views/landing_page/LPImageBlock";
import { DsIconLpCohort } from "components/DsIcon";
import { CheckIcon } from "@heroicons/react/16/solid";
import { Pricing } from "views/landing_page/home/Pricing";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function LandingPagePricing() {
  return (
    <>
    <div
        style={{
          background: "url(https://static.datasenses.io/datasense-bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Header />
        <main className="pb-16 pt-56 lg:pt-56">
          <div className={"grid grid-rows-1 gap-y-20 lg:gap-y-26 py-10"}>
            <div className={"lp-background-2"}>
              <div className={"grid grid-cols-1 lg:grid-cols-2 mx-auto max-w-7xl lg:px-8 gap-2"}>
                <div className={"flex items-center flex-justify"}>
                  <LPTextBlock
                    title={"Growth Analytics Provider"}
                    description={"We are eager to offer our support and be part of your company's journey towards efficient growth."}
                    description_items={[
                      <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                        className={"w-5 h-5 mr-2 text-violet-500"} /> 8 years in this industry</span>,
                      <span className={"flex flex-wrap justify-start items-center"}><CheckIcon
                        className={"w-5 h-5 mr-2 text-violet-500"} />Dedicated, you can contact us anytime, anywhere</span>,
                    ]}
                    type={"bg_light"}
                  />
                </div>
                <LPImageBlock
                  imageContent={<DsIconLpCohort />}
                />
              </div>
            </div>
          </div>
        </main>
        <Pricing/>
        <div className={"mt-30 lg:mt-40"}>
          <Faqs/>
        </div>
        <Footer/>
    </div>
    </>
  )
}
