import { useTranslation } from "react-i18next";
import { propertyInputType } from 'consts/chartConfig';

import PropertyInput from './PropertyInput';


export default function Input({
  value = [],
  onChange = () => { },
}) {
  const { t } = useTranslation();

  const addData = () => {
    const data = [...value];

    data.push({
      type: propertyInputType.USER_PROPERTIES,
    });

    onChange(data);
  };

  const setData = (index, newData) => {
    const data = [...value];

    data[index] = newData;

    onChange(data);
  };

  const deleteData = (index) => {
    const data = [...value];

    data.splice(index, 1);

    onChange(data);
  };

  return (
    <>
      {value.length > 0 && <div className={"grid grid-cols-1 gap-2"}>
        {value.map((item, index) => (
          <PropertyInput
            key={index}
            value={item}
            onChange={(data) => setData(index, data)}
            onDelete={() => deleteData(index)}
            className="mt-1"
          />
        ))}
      </div>}
      <div onClick={addData} className="ds-button-2 mt-2">
        + {t("PROPERTY")}
      </div>
    </>
  )
};
