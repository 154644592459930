import {isUndefinedOrEmpty} from "utils/index";

/**
 * Searches for items in a list based on a search term and search field.
 * @param {string} searchField - The field in the items to search against.
 * @param {string} searchTerm - The term to search for in the specified field.
 * @param {Array<Object>} items - The list of items to search within.
 * @returns {Array<Object>} An array of items matching the search criteria.
 */
export const searchItemInList = (searchField, searchTerm, items) => {
  if (!searchTerm) return items;
  if (items.length > 0) {
    const attributes = Object.keys(items[0]);

    const list = [];

    for (const current of items) {
      for (const attribute of attributes) {
        if (attribute === searchField) {
          const value = current[attribute];
          if (value && value.toLowerCase().includes(searchTerm.toLowerCase())) {
            const found = items.find((row) => row[searchField] === current[searchField]);
            if (found) {
              list.push(found);
            }
          }
        }
      }
    }
    return list;
  }
  return [];
}

/**
 * Generates an array of dates between a start date and an end date.
 * @param {string | Date} startDate - The start date.
 * @param {string | Date} endDate - The end date.
 * @returns {Array<string>} An array of date strings between the start and end dates.
 */
export const getDatesBetween = (startDate, endDate) => {
  const dateArray = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    dateArray.push(currentDate.toISOString().split('T')[0]);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
}

/**
 * Generates a date key for the current date.
 * @returns {string} A date key for the current date.
 */
export const getDateKeyNow = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(now.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export const titleCase = (s) => {
  if (isUndefinedOrEmpty(s)) {
    return "";
  }
  return s.replace(/^_*(.)|_+(.)/g, (s, c, d) => c ? c.toUpperCase() : ' ' + d.toUpperCase())
}

export function capitalizeAll(str) {
  return str
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
