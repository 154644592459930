export const whereTypes = {
  COUNT: 'count',
  AVERAGE_OF_PROPERTY: 'average_of_property',
  TOTAL_SUM_OF_PROPERTY: 'total_sum_of_property',
};

export const whereOperators= {
  GREATER_OR_EQUALS: '>=',
  BETWEEN: "between",
  LESS_THAN_OR_EQUALS: '<=',
  EQUALS: '=',
};
